import { memo, useEffect, useState } from 'react'

import { Checkbox } from '../inputs/Checkbox'
import { TextInput } from '../inputs/TextInput'

interface ICustomPriceRangeProps {
  id: string
  onChange: (val: boolean, filterText: string, start?: string, end?: string) => void
  type: string
  checkedProp: boolean
  hidePrefix?: boolean
}
export const CustomPriceRange = memo(
  ({ id, onChange, type, checkedProp, hidePrefix = false }: ICustomPriceRangeProps) => {
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [checked, setChecked] = useState(false)

    function handleChange(val: boolean) {
      send(val)
      const filterText = `${type}+${start || 0},${end || 999999}`
      onChange(val, filterText, start, end)
    }

    function handleStart(val: string) {
      setStart(val)
      const filterText = `${type}+${val || 0},${end || 999999}`
      onChange(checkedProp, filterText, start, end)
    }

    function handleEnd(val: string) {
      setEnd(val)
      const filterText = `${type}+${start || 0},${val || 999999}`
      onChange(checkedProp, filterText, start, end)
    }

    function send(val: boolean) {
      const filterText = `${type}+${start || 0},${end || 999999}`
      onChange(val, filterText, start, end)
    }

    useEffect(() => {
      setChecked(checkedProp)
      // const filterText = `${type}+${start || 0},${end || 999999}`
      // onChange(checkedProp, filterText, start, end)
    }, [checkedProp])

    // useEffect(() => {
    //   const filterText = `${type}+${start || 0},${end || 999999}`
    //   onChange(checkedProp, filterText, start, end)
    // }, [checkedProp, start, end])

    return (
      <div className="inline-flex items-center justify-between">
        <Checkbox id={id} onChange={handleChange} checked={checked} />
        <TextInput
          style={{ height: '2rem' }}
          value={start}
          onChange={handleStart}
          prefix={hidePrefix ? false : true}
        />
        <span className="mx-1">to</span>
        <TextInput
          style={{ height: '2rem' }}
          value={end}
          onChange={handleEnd}
          prefix={hidePrefix ? false : true}
        />
      </div>
    )
  },
)

CustomPriceRange.displayName = 'CustomPriceRange'
