import { ChevronDownIcon } from '@heroicons/react/solid'
import { memo, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { api } from 'services/api'

import { CustomPriceRange } from 'components/filters/CustomPriceRange'
import { createFilterFromAttribute } from 'components/filters/utils'
import { Checkbox } from 'components/inputs/Checkbox'
import { AndOrType, IAdminFilter } from 'data/globals/types'
import { useErrorAlert } from 'hooks/useErrorAlert'
import { IProductAttribute } from 'types/modelTypes'
import { capitalize } from 'utils/helpers'
import { useInit } from 'utils/hooks'

const PRICE_FILTERS = [
  [5, 10],
  [10, 15],
  [15, 20],
  [20, 25],
  [25, 50],
  [50, 75],
  [75, 100],
]

const INVENTORY_FILTERS = [
  [1, 250],
  [251, 500],
  [501, 1000],
  [1001, 2000],
  [2001, 5000],
  [5001, 10000],
]

interface IProductFiltersProps {
  filters: IAdminFilter[]
  setFilters: (filters: IAdminFilter[]) => void
  showExclusivesOnly: boolean
}
export const ProductFilters = memo(
  ({ filters, setFilters, showExclusivesOnly }: IProductFiltersProps) => {
    const { errorAlert } = useErrorAlert()

    const [categories, setCategories] = useState<IProductAttribute[]>([])
    const [subCategories, setSubCategories] = useState<IProductAttribute[]>([])
    const [brands, setBrands] = useState<IProductAttribute[]>([])
    const [colors, setColors] = useState<IProductAttribute[]>([])
    const [materials, setMaterials] = useState<IProductAttribute[]>([])

    const getProductAttributes = useCallback(async () => {
      try {
        const { data } = await api.ProductAttributes.getAll(true)
        setColors(data.Color)
        setBrands(data.Brand)
        setCategories(data.Category)
        setSubCategories(data.Subcategory)
        setMaterials(data.Material)
      } catch (error) {
        errorAlert(error, 'Could not find filters')
      }
    }, [alert])

    useInit(() => {
      getProductAttributes()
    })

    return (
      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        // onChange={(val, w) => { }}
      >
        <AccordionItem uuid="exclusive">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              GPS Exclusive
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="pb-2">
            {!showExclusivesOnly && (
              <AccordionItemPanelItem
                id="exclusive_box"
                item={{ name: 'Exclusive' }}
                label="GPS Exclusives"
                prop="exclusive"
                andOr="or"
                filters={filters}
                setFilters={setFilters}
              />
            )}
            <AccordionItemPanelItem
              id="warehouse_box"
              item={{ name: 'Warehouse' }}
              label="GPS Warehouse"
              prop="warehouse"
              andOr="or"
              filters={filters}
              setFilters={setFilters}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="categories">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Categories
            </AccordionItemButton>
          </AccordionItemHeading>
          {/* <CategoryFilterItem categories={categories} filters={filters} setFilters={setFilters} /> */}
          <AccordionItemPanel className="pb-2">
            {categories?.map((cat) => (
              <AccordionItemPanelItem
                key={`${cat.id}_cat`}
                id={`${cat.id}_cat`}
                item={cat}
                setFilters={setFilters}
                filters={filters}
                prop="category"
                andOr="or"
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="subcategories">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Sub Categories
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="pb-2">
            {subCategories?.map((cat) => (
              <AccordionItemPanelItem
                key={`${cat.id}_scat`}
                id={`${cat.id}_scat`}
                item={cat}
                setFilters={setFilters}
                filters={filters}
                prop="subCategory"
                andOr="or"
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="price">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Price
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="pb-2">
            <div className="pl-1 pr-2 text-xs outline-none">
              <CustomPriceRange
                id="cost"
                type="cost"
                checkedProp={
                  !!filters.find((fi) => {
                    // checked if filter object if greater than and first number in filter matches
                    return (
                      (fi.operator?.value === 'gte' || fi.operator?.value === 'lte') &&
                      fi.operator?.label.includes('custom')
                    )
                  }) || false
                }
                onChange={(val, filterText, start, end) => {
                  // const newFilters = filters.filter((f) => !f.operator?.label.includes('customcost'))
                  const newFilters = filters.filter((f) => f.prop?.value !== 'cost')
                  if (val) {
                    const ranges = []
                    if (!!start) {
                      ranges.push(
                        createFilterFromAttribute(
                          'cost',
                          start,
                          'gte',
                          AndOrType.int,
                          'Is greater than custom',
                        ),
                      )
                    }
                    if (!!end) {
                      ranges.push(
                        createFilterFromAttribute(
                          'cost',
                          end,
                          'lte',
                          AndOrType.int,
                          'is less than custom',
                        ),
                      )
                    }
                    setFilters([...newFilters, ...ranges])
                  } else {
                    setFilters(newFilters)
                  }
                }}
              />
            </div>
            {PRICE_FILTERS.map((f, idx) => {
              return (
                <li
                  key={idx}
                  className="transition-colors flex bg-white py-1 pl-1 text-xs outline-none duration-200 hover:bg-gray-100"
                >
                  <Checkbox
                    id={`price_${idx}`}
                    checked={
                      !!filters.find((fi) => {
                        // checked if filter object if greater than and first number in filter matches
                        return (
                          fi.operator?.value === 'gte' &&
                          fi.attr?.[0].value === f[0].toString() &&
                          !fi.operator?.label.includes('custom')
                        ) // filterText
                      }) || false
                    }
                    onChange={(val) => {
                      // only one can be selected at a time
                      const newFilters = filters.filter((f) => f.prop?.value !== 'cost')
                      if (val) {
                        const ranges = []
                        ranges.push(
                          createFilterFromAttribute(
                            'cost',
                            f[0],
                            'gte',
                            AndOrType.int,
                            'Is greater than',
                          ),
                        )
                        if (f[1]) {
                          ranges.push(
                            createFilterFromAttribute(
                              'cost',
                              f[1],
                              'lte',
                              AndOrType.int,
                              'Is less than',
                            ),
                          )
                        }
                        setFilters([...newFilters, ...ranges])
                      } else {
                        setFilters(newFilters)
                      }
                    }}
                    label={f.join(' - ')}
                    labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                    containerStyle="w-full"
                  />
                </li>
              )
            })}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="inventory">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Inventory
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="pb-2">
            <div className="pl-1 pr-2 text-xs outline-none">
              <CustomPriceRange
                id="inventory"
                type="inventory"
                hidePrefix={true}
                checkedProp={
                  !!filters.find((f) => f.operator?.label.includes('inventoryrange')) || false
                }
                onChange={(val, filterText) => {
                  const newFilters = filters.filter((f) => f.prop?.value !== 'inventoryrange')
                  // const newFilters = filters.filter((f) => !f.operator?.label.includes('inventorycs'))
                  if (val) {
                    // only one can be selected at a time
                    const newFilter = createFilterFromAttribute(
                      'inventoryrange',
                      filterText,
                      'between',
                      AndOrType.or,
                      'inventoryrange',
                    )
                    setFilters([...newFilters, newFilter])
                  } else {
                    setFilters(newFilters)
                  }
                }}
              />
            </div>
            {INVENTORY_FILTERS.map((f, idx) => {
              const filterText = `inventory+${f[0]},${f[1]}`
              return (
                <AccordionItemPanelItem
                  key={`inventory_${idx}`}
                  id={`inventory_${idx}`}
                  prop="inventoryrange"
                  singular={true}
                  andOr="between"
                  item={{ name: filterText }}
                  label={f.join(' - ')}
                  filters={filters}
                  setFilters={setFilters}
                />
              )
            })}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="brands">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Brands
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="pb-2">
            {brands?.map((b) => (
              <AccordionItemPanelItem
                key={`brand_${b.id}`}
                id={`brand_${b.id}`}
                item={b}
                filters={filters}
                setFilters={setFilters}
                prop={'brand'}
                andOr={'or'}
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="colors">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Colors
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="pb-2">
            {colors?.map((c) => (
              <AccordionItemPanelItem
                key={`color_${c.id}`}
                id={`color_${c.id}`}
                item={c}
                filters={filters}
                setFilters={setFilters}
                prop="colors"
                andOr={'or'}
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="materials">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Materials
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="pb-2">
            {materials?.map((m) => (
              <AccordionItemPanelItem
                key={`material_${m.id}`}
                id={`material_${m.id}`}
                item={m}
                prop="material"
                andOr="or"
                filters={filters}
                setFilters={setFilters}
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    )
  },
)

ProductFilters.displayName = 'ProductFilters'

interface IAccordionItemPanelItemProps {
  id: string
  item: any
  label?: string
  filters: IAdminFilter[]
  singular?: boolean
  setFilters: (filters: IAdminFilter[]) => void
  prop: string
  andOr: string
}
const AccordionItemPanelItem = memo(
  ({
    id,
    item,
    label,
    filters,
    singular,
    setFilters,
    prop,
    andOr,
  }: IAccordionItemPanelItemProps) => {
    const handleChange = (val: boolean) => {
      // inventory and price filters are limited to one at a time
      // but other filters can be multiple
      const newFilters = singular ? filters.filter((f) => f.prop?.value !== prop) : [...filters]
      if (val) {
        const newFilter = createFilterFromAttribute(prop, item.name, andOr)
        console.log('NEW FILTER', newFilter)
        setFilters([...newFilters, newFilter])
      } else {
        const removed = newFilters.filter((f) => f.attr?.[0].value !== item.name)
        setFilters(removed)
      }
    }

    return (
      <li
        className={`transition-colors flex bg-white py-1 pl-1 text-xs outline-none duration-200 hover:bg-gray-100`}
      >
        <Checkbox
          id={id}
          checked={filters.findIndex((f) => f.attr?.[0].value === item.name) > -1}
          onChange={handleChange}
          label={capitalize(label ?? item.name, true)}
          labelStyle={{ fontSize: '0.75rem', width: '100%' }}
          containerStyle="w-full"
        />
      </li>
    )
  },
)

AccordionItemPanelItem.displayName = 'AccordionItemPanelItem'
