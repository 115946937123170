import { Disclosure, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { classNames, generateInitials } from 'utils/helpers'

import { ExportButton } from 'components/buttons/ExportButton'
import ExportCsvButton from 'components/buttons/ExportCsvButton'
import ExportQuoteButton from 'components/buttons/ExportQuoteButton'
import { SaveDeckButton } from 'components/buttons/SaveDeckButton'
import { Button } from 'components/small/Button'

import { clearDeck, startNewDeck } from 'data/decks'
import { resetPriceAdjustOptions, setCurrentList, setFilters, setSearchTerm } from 'data/globals'
import { logOut } from 'data/user'
import { selectIsAdmin, selectUser } from 'data/user/selectors'

import { ReactComponent as GPSLogo } from '../assets/svgs/gps_logo.svg'

export const NavBar = ({ builder }) => {
  const history = useHistory()
  const location = useLocation()

  const isAdmin = useSelector(selectIsAdmin)
  const user = useSelector(selectUser)

  const profile = [
    {
      label: 'My Profile',
      handler: async () => {
        history.push(`/profile/${user?.id}`)
      },
    },
    {
      label: 'Search Products',
      handler: async () => {
        history.push('/home')
      },
    },
    {
      label: 'Sign out',
      handler: async () => {
        await logOut()
        localStorage.clear()
        history.replace('/login')
      },
    },
  ]

  if (isAdmin) {
    profile.splice(2, 0, { label: 'Manage Users', handler: () => history.push('/admin/user_list') })
  }

  function goHome() {
    history.push('/')
  }

  function startNew() {
    resetPriceAdjustOptions()
    startNewDeck()
    history.push('/builder')
  }

  function handleExit() {
    clearDeck()
    setFilters([])
    setSearchTerm('')
    setCurrentList(null)
    setTimeout(() => {
      history.replace('/products')
    }, 500)
  }

  return (
    <Disclosure
      as="nav"
      className={`fixed left-0 right-0 top-0 z-50 bg-gray-800`}
      style={{ minHeight: '67px' }}
    >
      {({ open }) => (
        <div className="max-w-8xl mx-auto px-8 sm:px-16">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                {open ? (
                  <Disclosure.Button>
                    <GPSLogo onClick={goHome} className="h-6 cursor-pointer text-white md:h-8" />
                  </Disclosure.Button>
                ) : (
                  <GPSLogo
                    onClick={goHome}
                    className="h-6 w-auto cursor-pointer text-white md:h-8"
                  />
                )}
              </div>
            </div>
            <div className="block">
              <div className="ml-4 flex items-center md:ml-6">
                {isAdmin && !location?.pathname?.includes('builder') && (
                  <Button onClick={startNew} variant="primary" className="mr-4" shadow="none">
                    + NEW DECK
                  </Button>
                )}
                {builder && (
                  <>
                    <ExportQuoteButton className="mr-4" />
                    <ExportCsvButton className="mr-4" />
                    <ExportButton className="mr-4" />
                    <SaveDeckButton className="mr-4" user={user} />
                    <Button
                      variant="primary"
                      size="s"
                      onClick={handleExit}
                      className="mr-4"
                      shadow="none"
                    >
                      EXIT BUILDER
                    </Button>
                  </>
                )}

                {/* Profile dropdown */}
                {!builder && (
                  <Menu as="div" className="relative z-20 ml-3">
                    {({ open }) => (
                      <>
                        <div>
                          <MenuButton className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-500 text-sm text-white">
                              {generateInitials(user)}
                            </div>
                          </MenuButton>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <MenuItems
                            static
                            className="absolute right-0 mt-2 w-32 origin-top-right rounded-md bg-white py-1 shadow-full-dark ring-0 focus:outline-none"
                          >
                            {profile.map((item) => (
                              <MenuItem key={item.label}>
                                {({ active }) => (
                                  <button
                                    onClick={item.handler}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block w-full px-2 py-1 text-left text-sm text-gray-800 outline-none ring-0 focus:outline-none',
                                    )}
                                  >
                                    {item.label}
                                  </button>
                                )}
                              </MenuItem>
                            ))}
                          </MenuItems>
                        </Transition>
                      </>
                    )}
                  </Menu>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  )
}
