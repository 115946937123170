import { Button as HeadlessButton } from '@headlessui/react'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { useMemo } from 'react'
import { classNames } from 'utils/helpers'

interface IButtonProps {
  children: React.ReactNode
  onClick?: () => void
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  variant?: 'primary' | 'secondary' | 'warning' | 'outline' | 'accent'
  size?: 'xs' | 's' | 'm' | 'l'
  shadow?: 'none' | 'lg' | 'xl'
  loading?: boolean
  fullWidth?: boolean
  selected?: boolean
}
export const Button = ({
  children,
  onClick,
  className,
  disabled,
  loading,
  variant = 'primary',
  type = 'button',
  size,
  fullWidth = false,
  selected,
  shadow,
}: IButtonProps) => {
  const variantClasses = useMemo(() => {
    switch (variant) {
      case 'primary':
        return 'bg-purple-500 data-[hover]:bg-purple-700 text-white'
      case 'secondary':
        return 'bg-gray-500 text-white'
      case 'outline':
        return classNames(
          'border border-purple-500 text-purple-500 data-[hover]:bg-gray-100',
          selected ? 'bg-gray-500/10 shadow-none' : '',
        )
      case 'warning':
        return 'bg-red-600 data-[hover]:bg-red-700 text-white'
      case 'accent':
        return 'bg-green-500 data-[hover]:bg-green-700 text-white'
      default:
        return 'bg-purple-500 data-[hover]:bg-purple-700 text-white'
    }
  }, [variant, selected])

  const sizeClasses = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'px-2 text-xs h-7'
      case 's':
        return 'px-3 text-sm h-8'
      case 'm':
        return 'px-4 text-base h-8'
      case 'l':
        return 'px-5 text-lg h-10'
      default:
        return 'px-3 text-base h-9'
    }
  }, [size])

  const shadowClasses = useMemo(() => {
    switch (shadow) {
      case 'lg':
        return 'shadow-lg hover:shadow-none shadow-gray-500/50'
      case 'xl':
        return 'shadow-xl hover:shadow-none shadow-gray-500/50'
      case 'none':
        return 'shadow-none hover:shadow-none'
      default:
        return 'shadow hover:shadow-none shadow-gray-500/50'
    }
  }, [shadow])

  return (
    <HeadlessButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'relative inline-flex justify-center items-center gap-2 rounded-md focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white whitespace-nowrap',
        variantClasses,
        sizeClasses,
        shadowClasses,
        fullWidth ? 'w-full' : '',
        className,
      )}
    >
      {children}
      {loading && (
        <div className="absolute flex bg-black/30 inset-0 items-center justify-center">
          <LoadingSpinner size="s" />
        </div>
      )}
    </HeadlessButton>
  )
}
