import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'data/store'
import constants from 'utils/constants'
import { IOrgMarkup } from './types'

export const selectIsAdmin = (state: RootState) => state.user?.role === constants.ROLES.ADMIN

export const selectUser = (state: RootState) => state.user

export const selectUserRole = (state: RootState) => state.user?.role

export const selectOrganizationMarkup = createSelector(
  (state: RootState) => state.user,
  (user) => {
    const isAdmin = user?.role === constants.ROLES.ADMIN
    return {
      tier1: isAdmin ? 1 : user?.organization?.tier1 ?? 0.3,
      tier2: isAdmin ? 1 : user?.organization?.tier2 ?? 0.3,
      tier3: isAdmin ? 1 : user?.organization?.tier3 ?? 0.3,
      tier4: isAdmin ? 1 : user?.organization?.tier4 ?? 0.3,
      tier5: isAdmin ? 1 : user?.organization?.tier5 ?? 0.3,
    } as IOrgMarkup
  },
)

export const selectExclusivesOnly = (state: RootState) =>
  state.user?.organization?.exclusivesOnly ?? false

export const selectOrganizationIsAllAccess = (state: RootState) =>
  state.user?.organization?.allAccess ?? false
