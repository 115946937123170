import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { IUser } from 'types/modelTypes'
import { MyListsTabLists } from './MyListsTab--Lists'
import { MyListsTabDetail } from './MyListsTab--detail'

/* onAddComplete is used from within the builder to close the modal */
interface IMyListsTabProps {
  user?: IUser
  slot?: number | null
  onAddComplete?: () => void
  forceFetch?: boolean
}
export function MyListsTab({
  user,
  slot = null,
  onAddComplete = () => {},
  forceFetch,
}: IMyListsTabProps) {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <MyListsTabLists forceFetch={!!forceFetch} />
      </Route>
      <Route
        exact
        path={`${path}/list/:listId`}
        render={() => <MyListsTabDetail slot={slot} onAddComplete={onAddComplete} user={user} />}
      />
    </Switch>
  )
}
