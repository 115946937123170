import { FolderAddIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { setCrumbs } from 'data/globals'
import { IDirectoryCrumb } from 'data/globals/types'
import { useAppSelector } from 'data/reduxHooks'
import { selectUser } from 'data/user/selectors'
import { useErrorAlert } from 'hooks/useErrorAlert'
import { api } from 'services/api'
import { IContinuityMaster, IDirectory } from 'types/modelTypes'
import AddFolderModal from '../modals/AddFolderModal'
import Breadcrumbs from './Breadcrumbs'
import DirectoryList from './DirectoryList'

interface DirectoryLevel {
  child: 'client' | 'sub_1' | 'sub_2'
}

type DirectoryLevels = Record<string, DirectoryLevel>

const initialCrumbs: IDirectoryCrumb[] = [
  {
    name: 'Users',
    level: 'users',
    directoryId: null,
    userId: null,
    current: true,
  },
]

export const directoryLevels: DirectoryLevels = {
  user: {
    child: 'client',
  },
  client: {
    child: 'sub_1',
  },
  sub_1: {
    child: 'sub_2',
  },
}

interface IDirectoryProps {
  onDirChange?: (dir: IDirectoryCrumb) => void
}
export function Directory({ onDirChange }: IDirectoryProps) {
  const { errorAlert } = useErrorAlert()
  const crumbs = useAppSelector((state) => state.globals.crumbs)
  const user = useSelector(selectUser)

  const [folders, setFolders] = useState<IDirectory[]>([])
  const [masterFiles, setMasterFiles] = useState<IContinuityMaster[]>([])
  const [isFolderModalVisible, setIsFolderModalVisible] = useState(false)

  const currentCrumb = crumbs[crumbs.length - 1]
  const disableFolderAdd = currentCrumb?.level === 'users' || currentCrumb?.level === 'sub_2'

  const handleCrumbChange = async () => {
    onDirChange?.(currentCrumb)
    if (currentCrumb?.directoryId) {
      // if directoryId then it is not a top level directory, so fetch its children
      await getDirectoryChildren(currentCrumb.directoryId)
    } else {
      setCrumbs(initialCrumbs)
      getAllTopLevelNodes()
    }
  }

  useEffect(() => {
    handleCrumbChange()
  }, [currentCrumb])

  async function getAllTopLevelNodes() {
    try {
      const { data } = await api.Directory.getAllTopLevel({ withMaster: !!user?.masterAccess })
      setFolders(data)
    } catch (error) {
      errorAlert(error, 'Error fetching parent directories')
    }
  }

  async function getDirectoryChildren(dirId: number): Promise<IDirectoryCrumb | null> {
    try {
      const { data } = await api.Directory.getWithChildren(dirId)
      if (data.children) {
        setFolders(data.children)
      }
      if (data.masters) {
        setMasterFiles(data.masters)
      }
      return {
        name: data.name,
        userId: data.userId,
        directoryId: data.id,
        current: true,
        level: data.level,
      } as IDirectoryCrumb
    } catch (error) {
      errorAlert(error, 'Error fetching directory children')
      return null
    }
  }

  function handleCrumbClick(crumb: IDirectoryCrumb | null) {
    // if (!crumb && user) {
    //   // this occurs if the home icon is clicked in profile view
    //   return
    // } else
    if ((crumb === null || crumb.directoryId === null) && !user) {
      setCrumbs(initialCrumbs)
      getAllTopLevelNodes()
    } else {
      const desiredCrumbIndex = crumbs.findIndex((c) => c.name === crumb?.name)
      const newCrumbs = crumbs.slice(0, desiredCrumbIndex + 1)
      setCrumbs(newCrumbs)
    }
  }

  async function handleFolderClick(item: IDirectory) {
    try {
      if (!item.id) {
        alert('No id found for folder')
        return
      }
      const newCrumb = await getDirectoryChildren(item.id)
      if (newCrumb) {
        setCrumbs([...crumbs, newCrumb])
      }
    } catch (error) {}
  }

  async function handleFileClick(item: IContinuityMaster) {
    // TODO for now, you can only click on folders
    // try {
    //   if (currentDeck.filename) {
    //     // then we are accessing this from the builder and any file clicked on should replace the
    //     // current decks filename for saving
    //     updateCurrentDeck({
    //       filename: item.filename,
    //     })
    //   } else {
    //     // otherwise fetch load that deck into state
    //     const { data } = await api.ContinuityMaster.getById(item.id, true)
    //     updateCurrentSlide(data.continuities[0])
    //     updateCurrentDeck(data)
    //     history.replace('/builder')
    // }
    // } catch (error) {
    //   console.log('ERROR', error)
    // }
  }

  async function handleAddFolder(name: string) {
    const parentDir = currentCrumb
    try {
      const { data } = await api.Directory.create({
        userId: parentDir.userId || undefined,
        name,
        parentId: parentDir.directoryId,
        type: 'folder',
        level: directoryLevels[parentDir.level].child,
      })
      setFolders([...folders, data])
      setIsFolderModalVisible(false)
    } catch (error) {
      errorAlert(error, 'Error creating folder')
    }
  }

  // async function handleAddFile() {
  //   const parentDir = currentCrumb
  //   try {
  //     const dir = await api.Directory.create({
  //       userId: user.username,
  //       name,
  //       parentId: parentDir.directoryId,
  //       type: 'folder',
  //     })
  //     setStructure([...structure, dir])
  //     setIsFolderModalVisible(false)
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  return (
    <div>
      <div className=" mb-4 flex items-center justify-between">
        <Breadcrumbs pages={crumbs} onClick={handleCrumbClick} />
        <div className="flex items-center">
          {!disableFolderAdd && (
            <button className="focus:outline-none" onClick={() => setIsFolderModalVisible(true)}>
              <FolderAddIcon className="mr-2 h-6 w-6 text-gray-500" />
            </button>
          )}
          {/* <button className="focus:outline-none" onClick={() => setIsFileModalVisible(true)}>
            <DocumentAddIcon className="h-6 w-6 text-gray-500" />
          </button> */}
        </div>
      </div>
      <DirectoryList
        folders={folders}
        files={masterFiles}
        onFolderClick={handleFolderClick}
        onFileClick={handleFileClick}
      />
      <div className="h-2"></div>
      <AddFolderModal
        show={isFolderModalVisible}
        title={`Add folder to ${crumbs[crumbs.length - 1]?.name}`}
        onAccept={handleAddFolder}
        onClose={() => setIsFolderModalVisible(false)}
      />
    </div>
  )
}
