import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react'
import { useAppSelector } from 'data/reduxHooks'
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'

import { LoadingSpinner } from '../LoadingSpinner'
import { MultiSelectBar } from '../MultiSelectBar'
import { Checkbox } from '../inputs/Checkbox'

import { classNames, dateStringFormat, formatOrgTierPricing, formatter } from '../../utils/helpers'

import { addProductToSlide, createSlideFromProduct, setAlternateImage } from 'data/decks'
import { selectCurrentSlide } from 'data/decks/selectors'
import { addFavorites, removeFavorites } from 'data/favorites'
import { updateSelectedProducts } from 'data/selections'
import { selectedProducts } from 'data/selections/selectors'
import { selectIsAdmin, selectUser } from 'data/user/selectors'

import { api } from '../../services/api'

import { ProductTagsList } from 'components/medium/ProductTagsList'
import { Button } from 'components/small/Button'
import { SelectedProducts } from 'data/selections/types'
import { IOrgMarkup } from 'data/user/types'
import { useErrorAlert } from 'hooks/useErrorAlert'
import { IProduct, IProductWithChildAssets } from 'types/modelTypes'
import { ReactComponent as Heart } from '../../assets/svgs/heart.svg'
import { ReactComponent as HeartFilled } from '../../assets/svgs/heart_filled.svg'

const NO_OP = () => {}

interface IProductDetailViewProps {
  id: number
  slot: number
  onAddSlideComplete?: () => void
  onBrandClick?: (product: IProduct) => void
  onVendorClick?: (product: IProduct) => void
  onTagClick?: (tag: string) => void
  onSubcategoryClick?: (subCategory: string, type: string) => void
  orgMarkup: IOrgMarkup
  fromNotes?: boolean
}
export const ProductDetailView = ({
  id,
  slot,
  onAddSlideComplete,
  onBrandClick = NO_OP,
  onVendorClick = NO_OP,
  onTagClick = NO_OP,
  onSubcategoryClick = NO_OP,
  orgMarkup,
  fromNotes = false,
}: IProductDetailViewProps) => {
  const alert = useAlert()
  const { errorAlert } = useErrorAlert()
  const history = useHistory()

  const user = useAppSelector(selectUser)
  const isAdmin = useAppSelector(selectIsAdmin)
  const selected = useAppSelector(selectedProducts)
  const favorites = useAppSelector((state) => state.favorites)
  const { currentSlide } = useAppSelector(selectCurrentSlide)

  const [product, setProduct] = useState<IProductWithChildAssets | null>(null)
  const [loading, setLoading] = useState(true)
  const [hoveredChild, setHoveredChild] = useState<number>(-1)

  const getDetail = async () => {
    try {
      const { data } = await api.Products.getByIdWithChildren(id)
      setProduct(data)
    } catch (error) {
      errorAlert(error, 'Error fetching product details')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      getDetail()
    }
  }, [id])

  function handleChildClick(childId: number) {
    history.push(`/products/${childId}`)
  }

  async function handleFavorite() {
    try {
      await addFavorites([product!.id])
    } catch (error) {
      errorAlert(error, 'Error setting favorite')
    }
  }

  async function handleUnfavorite() {
    try {
      await removeFavorites([product!.id])
    } catch (error) {
      errorAlert(error, 'Error removing favorite')
    }
  }

  function onAddToSlide() {
    if (currentSlide.products.some((p) => p.id === product!.id)) {
      alert.error('Product already in slide')
      return
    }
    if (fromNotes) {
      createSlideFromProduct(product)
    } else {
      addProductToSlide({ slot, product })
    }
    onAddSlideComplete?.()
  }

  function onCheckboxChange(val: boolean) {
    if (val && product?.id) {
      updateSelectedProducts({
        ...selected,
        [product.id]: product,
      })
    } else {
      const updated = { ...selected } as SelectedProducts
      delete updated[Number(product!.id)]
      updateSelectedProducts(updated)
    }
  }

  function showProductDimensions() {
    return (
      (product!.productHeight && product!.productHeight > 0) ||
      (product!.productWidth && product!.productWidth > 0) ||
      (product!.productLength && product!.productLength > 0)
    )
  }

  function showMasterCartonDimensions() {
    return (
      (product!.masterCartonHeight && product!.masterCartonHeight > 0) ||
      (product!.masterCartonWidth && product!.masterCartonWidth > 0) ||
      (product!.masterCartonLength && product!.masterCartonLength > 0) ||
      (product!.masterCartonWeight && product!.masterCartonWeight > 0) ||
      (product!.masterCartonQuantity && product!.masterCartonQuantity > 0)
    )
  }

  function showTieredPricing() {
    return (product!.tier1 || product!.tier2 || product!.tier3 || product!.tier4) && isAdmin
  }

  const download = (url: string) => {
    if (!url.startsWith('https')) {
      url = url.replace('http', 'https')
    }
    fetch(url, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'image.png') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch((err) => {
        console.error(err)
        alert.error(err)
      })
  }

  return (
    <>
      {product && (
        <div className="mx-auto flex w-full flex-col">
          <div className="mx-auto px-4 py-8 sm:py-4">
            <div className="md:grid md:grid-cols-2 md:items-start md:gap-x-8">
              {/* Image gallery */}
              <TabGroup
                as="div"
                className="flex flex-col-reverse"
                onChange={(index) => {
                  if (slot !== null) {
                    setAlternateImage({
                      productId: product.id,
                      altIdx: index,
                    })
                  }
                }}
              >
                {/* Image selector */}
                <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                  <TabList className="grid grid-cols-4 gap-6">
                    {product.imageAssets?.map((image, idx) => (
                      <Tab
                        key={`${idx}_img`}
                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:shadow-full-light focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                      >
                        {({ selected }) => (
                          <>
                            <span className="absolute inset-0 overflow-hidden rounded-md">
                              <img
                                src={image}
                                className="h-full w-full object-contain object-center"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected ? 'ring-purple-500' : 'ring-transparent',
                                'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2',
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                  </TabList>
                </div>

                <TabPanels className="aspect-h-1 aspect-w-1 w-full">
                  {product.imageAssets?.map((image, idx) => (
                    <Tab.Panel key={`${idx}_img_tab`}>
                      <img
                        onClick={() => download(image)}
                        src={image}
                        className="h-full w-full cursor-pointer object-contain object-center shadow-full-light sm:rounded-lg"
                      />
                    </Tab.Panel>
                  ))}
                </TabPanels>
              </TabGroup>

              {/* Product info */}
              <div className="flex h-full flex-grow flex-col px-4 py-6 sm:px-0 md:py-0">
                {/* Vendor, brand, productId and updated date */}
                <div className="min-h-8 mb-2 flex flex-col text-sm">
                  <div className="mr-4 cursor-pointer text-purple-500">
                    {product.vendor && isAdmin && (
                      <button onClick={() => onVendorClick(product)} className="hover:opacity-50">
                        {product.vendor} /&nbsp;
                      </button>
                    )}
                    {product.brand && (
                      <button onClick={() => onBrandClick(product)} className="hover:opacity-50">
                        {product.brand} /&nbsp;
                      </button>
                    )}
                    {product.productId && <span>Item #{product.manufacturerId}</span>}
                  </div>
                  <div className="mr-4 cursor-pointer text-purple-500">
                    {product.category && (
                      <button
                        onClick={() => onSubcategoryClick(product.category!, 'category')}
                        className="hover:opacity-50"
                      >
                        {product.category}
                      </button>
                    )}
                    {product.subCategory && (
                      <button
                        onClick={() => onSubcategoryClick(product.subCategory!, 'subCategory')}
                        className="hover:opacity-50"
                      >
                        {product.subCategory}
                      </button>
                    )}
                    {product.subCategory2 && (
                      <button
                        onClick={() => onSubcategoryClick(product.subCategory2!, 'subCategory2')}
                        className="hover:opacity-50"
                      >
                        &nbsp;/ {product.subCategory2}
                      </button>
                    )}
                    {product.subCategory3 && (
                      <button
                        onClick={() => onSubcategoryClick(product.subCategory3!, 'subCategory3')}
                        className="hover:opacity-50"
                      >
                        &nbsp;/ {product.subCategory3}
                      </button>
                    )}
                    {product.subCategory4 && (
                      <button
                        onClick={() => onSubcategoryClick(product.subCategory4!, 'subCategory4')}
                        className="hover:opacity-50"
                      >
                        &nbsp;/ {product.subCategory4}
                      </button>
                    )}
                  </div>
                  Updated: {dateStringFormat(product.salsifyUpdatedAt)}
                </div>
                {/* title and checkboxes */}
                <div className="flex items-start justify-between">
                  <h1 className="mr-4 text-3xl font-extrabold tracking-tight text-gray-900">
                    {product.name}
                  </h1>
                  <div className="ml-4 flex items-center">
                    {slot === null && (
                      <Checkbox
                        id={product!.id.toString()}
                        size={5}
                        containerStyle="mr-2"
                        checked={selected[Number(product!.id)] !== undefined}
                        onChange={onCheckboxChange}
                      />
                    )}
                    {favorites.includes(product.id) ? (
                      <HeartFilled onClick={handleUnfavorite} className="h-6 w-6 cursor-pointer" />
                    ) : (
                      <Heart onClick={handleFavorite} className="h-6 w-6 cursor-pointer" />
                    )}
                  </div>
                </div>

                <div className="mt-3 flex justify-between">
                  <h2 className="sr-only">Product information</h2>
                  <p className="text-2xl font-bold text-gray-900">
                    {formatOrgTierPricing(product.cost, orgMarkup, isAdmin)}
                    {/* {formatter.format(roundUp(isAdmin ? product.cost : product.cost / orgMarkup))} */}
                  </p>
                  {slot !== null || fromNotes ? (
                    <Button onClick={onAddToSlide} variant="primary">
                      Add to Slide
                    </Button>
                  ) : null}
                </div>

                <section aria-labelledby="details-heading" className="mt-3">
                  <h2 id="details-heading" className="sr-only">
                    Additional details
                  </h2>

                  {/* Divider */}
                  <div className="divide-gray-200 border-t text-gray-700" />

                  {/* Description */}
                  <div className="mt-3">
                    <h3 className="sr-only">Description</h3>

                    <div className="text-base text-gray-700">
                      {product.description || 'No description'}
                    </div>
                  </div>
                  {/* Inventory */}
                  {product.inventory && <DetailItem label="Inventory" value={product.inventory} />}
                  {/* LeadTime */}
                  {product.leadTime && <DetailItem label="Lead Time" value={product.leadTime} />}
                  {/* Incoming Inventory */}
                  {!!product.incomingInventory1 && product.incomingInventory1Date && (
                    <DetailItem
                      label="Incoming Inventory"
                      value={`${product.incomingInventory1} expected on{' '}
                        ${dateStringFormat(product.incomingInventory1Date)}`}
                    />
                  )}
                  {/* Freight Cost */}
                  {product.freightCost && (
                    <DetailItem
                      label="Freight Cost"
                      value={formatter.format(product.freightCost)}
                    />
                  )}
                  {/* Handling */}
                  {product.handling && (
                    <DetailItem
                      label="Product Handling"
                      value={formatter.format(product.handling)}
                    />
                  )}
                  {/* Product Dimensions */}
                  {showProductDimensions() && (
                    <div className="mt-3">
                      <span className={'text-sm font-bold text-gray-900'}>Product Dimensions</span>
                      <ul role="list" className="mb-2 text-sm">
                        {product.productHeight && (
                          <li>
                            Height: {product.productHeight} {product.productDimensionUnit}
                          </li>
                        )}
                        {product.productWidth && (
                          <li>
                            Width: {product.productWidth} {product.productDimensionUnit}
                          </li>
                        )}
                        {product.productLength && (
                          <li>
                            Length: {product.productLength} {product.productDimensionUnit}
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                  {/* Carton Dimensions */}
                  {showMasterCartonDimensions() ? (
                    <div className="mt-3">
                      <span className={'text-sm font-bold text-gray-900'}>
                        Master Carton Dimensions
                      </span>
                      <ul role="list" className="mb-2 text-sm">
                        {product.masterCartonHeight && (
                          <li>
                            Height: {product.masterCartonHeight}{' '}
                            {product.masterCartonDimensionalUnit}
                          </li>
                        )}
                        {product.masterCartonWidth && (
                          <li>
                            Width: {product.masterCartonWidth} {product.masterCartonDimensionalUnit}
                          </li>
                        )}
                        {product.masterCartonLength && (
                          <li>
                            Length: {product.masterCartonLength}{' '}
                            {product.masterCartonDimensionalUnit}
                          </li>
                        )}
                        {product.masterCartonWeight && (
                          <li>Weight: {product.masterCartonWeight} lbs</li>
                        )}
                        {!!product.masterCartonQuantity && (
                          <li>Quantity: {product.masterCartonQuantity}</li>
                        )}
                      </ul>
                    </div>
                  ) : null}
                  {/* Tiered pricing */}
                  {showTieredPricing() && (
                    <div className="mt-3">
                      <span className={'text-sm font-bold text-gray-900'}>Tiered Costs</span>
                      <ul role="list" className="mb-2 text-sm">
                        <li>
                          Tier 1 {formatter.format(product.tier1 || 0)} at {product.tier1Qty}
                        </li>
                        <li>
                          Tier 2 {formatter.format(product.tier2 || 0)} at {product.tier2Qty}
                        </li>
                        <li>
                          Tier 3 {formatter.format(product.tier3 || 0)} at {product.tier3Qty}
                        </li>
                        <li>
                          Tier 4 {formatter.format(product.tier4 || 0)} at {product.tier4Qty}
                        </li>
                      </ul>
                    </div>
                  )}
                  {/* Colors */}
                  {product.colors && (
                    <div className="mt-3">
                      <span className={'mb-3 text-sm font-bold text-gray-900'}>Color</span>
                      <br />
                      {product.colors.split(',').map((p, idx) => (
                        <span key={idx} className="mr-4 text-sm">
                          {p}
                        </span>
                      ))}
                    </div>
                  )}
                  {/* Children Images */}
                  <div className="mt-3">
                    <ul className="flex flex-wrap">
                      {product.children?.map((child, idx) => (
                        <li
                          onClick={() => handleChildClick(child.id)}
                          key={`${child.id}_${idx}`}
                          className="relative mb-2 mr-2 cursor-pointer rounded border"
                          onMouseOver={() => setHoveredChild(child.id)}
                          onMouseLeave={() => setHoveredChild(-1)}
                        >
                          <img
                            src={child.computedPrimaryImage}
                            alt={child.name}
                            className="h-8 w-8 object-contain"
                          />
                          {hoveredChild === child.id && (
                            <span
                              className="absolute left-0 z-10 rounded bg-white text-xs shadow-full-light"
                              style={{ padding: '2px 4px', top: '-6px' }}
                            >
                              {child.colors ?? 'Unknown'}
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </section>
                {/* Tags */}
                {product?.tags && <ProductTagsList tags={product.tags} onClick={onTagClick} />}
              </div>
            </div>
          </div>
          {user && <MultiSelectBar user={user} />}
        </div>
      )}
      {loading && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
    </>
  )
}

const DetailItem = ({ label, value }: { label: string; value: string | number }) => {
  return (
    <div className="mt-3">
      <span className={'mt-3 text-sm font-bold text-gray-900'}>{label}</span>
      <p className="text-sm">{value}</p>
    </div>
  )
}
