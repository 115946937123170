import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { fetchDeck, setCurrentSlideIndex } from 'data/decks'
import { setCurrentDirectory } from 'data/globals'
import { api } from 'services/api'

import { LoadingSpinner } from 'components/LoadingSpinner'
import { MyContinuitiesCard } from 'components/MyContinuitiesCard'
import { BackButton } from 'components/buttons/BackButton'
import { SearchInput } from 'components/inputs/SearchInput'
import { GenericModal } from 'components/modals/GenericModal'

import { ReactComponent as EmptyState } from '../assets/svgs/empty_state.svg'

export function MyContinuitiesTab({ user }) {
  const alert = useAlert()
  const history = useHistory()

  const currentDirectory = useSelector((state) => state.globals.currentDirectory)

  const [parentFolder, setParentFolder] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResult, setSearchResult] = useState(null)
  const [showWarning, setShowWarning] = useState(false)
  const [folderForDeletion, setFolderForDeletion] = useState(null)
  const [crumbs, setCrumbs] = useState([])

  useEffect(() => {
    if (currentDirectory && !crumbs.includes(currentDirectory.name)) {
      setCrumbs((c) => [...c, currentDirectory.name])
    }
  }, [parentFolder, currentDirectory])

  function updateCurrentDirectory(d) {
    setCurrentDirectory(d)
  }

  async function handleItemClick(item) {
    try {
      if (item.type === 'folder') {
        setParentFolder(currentDirectory)
        const { data } = await api.Directory.getWithChildren(item.id)
        updateCurrentDirectory(data)
      } else {
        if (item.master) {
          await fetchDeck({ id: item.master.id, withProducts: true })
        } else {
          await fetchDeck({ id: item.id, withProducts: true })
        }
        setCurrentSlideIndex(0)
        history.replace('/builder')
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  async function handleBack() {
    try {
      const { data } = await api.Directory.getWithChildren(currentDirectory.parentId)
      updateCurrentDirectory(data)
      if (data.parentId) {
        const parent = await api.Directory.getWithChildren(data.parentId)
        setParentFolder(parent)
      } else {
        setParentFolder(null)
      }
      setCrumbs(crumbs.slice(0, -1))
    } catch (error) {}
  }

  const fetchMyContinuities = async () => {
    try {
      setCrumbs([])
      const { data } = await api.User.getDirectory(user.id)
      const { data: userDirWithChildren } = await api.Directory.getWithChildren(data.id)
      updateCurrentDirectory(userDirWithChildren)
    } catch (error) {
      // alert.error(error.message)
    }
  }

  const searchContintuities = async () => {
    try {
      const { data } = await api.Continuity.search(searchTerm)
      setSearchResult(data)
    } catch (error) {
      alert.error(error.message)
    }
  }

  const { isLoading, error } = useQuery(['my-continuities', user.id], () => fetchMyContinuities(), {
    refetchOnWindowFocus: false,
  })

  const { isLoading: isContinuitiesLoading, error: continuitiesError } = useQuery(
    ['my-continuities-search', searchTerm],
    () => searchContintuities(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!searchTerm.trim(),
    },
  )

  useEffect(() => {
    const e = error || continuitiesError
    if (e) {
      alert.error(e.message)
    }
  }, [error, continuitiesError])

  async function handleDeleteMaster(item) {
    try {
      await api.ContinuityMaster.delete(item.id)
      fetchMyContinuities()
      alert.success(`${item.filename} has been deleted`)
    } catch (error) {
      alert.error(error.message)
    }
  }

  async function handleDeleteFolder() {
    try {
      await api.Directory.delete(folderForDeletion.id)
      fetchMyContinuities()
      alert.success(`${folderForDeletion.name} has been deleted`)
      setShowWarning(false)
      setFolderForDeletion(null)
    } catch (error) {
      alert.error(error.message)
    }
  }

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  return (
    <>
      {currentDirectory ? (
        <div className="flex w-full flex-col">
          <div
            className="sticky top-0 z-10 flex items-center justify-between bg-white py-2"
            style={{ minHeight: '54px' }}
          >
            {currentDirectory.parentId !== null ? (
              <div className="flex">
                <BackButton
                  onClick={handleBack}
                  text={`Back to ${parentFolder?.name ?? 'My Continuities'}`}
                />
                <h3 className="ml-12 mr-8">{crumbs.join(' / ')}</h3>
              </div>
            ) : (
              <SearchInput
                className="h-9 w-1/2"
                onChange={handleSearch}
                placeholder="Search by reference number"
                type="number"
              />
            )}
          </div>
          {searchResult ? (
            <ul
              role="list"
              className="grid grid-cols-1 gap-x-4 gap-y-8 px-4 pt-2 sm:grid-cols-3 sm:gap-x-4 md:grid-cols-4 md:gap-x-6 xl:grid-cols-6 xl:gap-x-8"
            >
              <MyContinuitiesCard
                item={searchResult}
                onClick={handleItemClick}
                onDelete={handleDeleteMaster}
              />
            </ul>
          ) : searchTerm ? (
            <div className="mt-6 w-full">
              <p className="text-center font-semibold">
                Sorry, this person doesn&apos;t have any saved continuities to show.
              </p>
              <EmptyState className="mx-auto h-64" />
            </div>
          ) : (
            <ul
              role="list"
              className="grid grid-cols-1 gap-x-4 gap-y-8 px-4 pt-2 sm:grid-cols-3 sm:gap-x-4 md:grid-cols-4 md:gap-x-6 xl:grid-cols-6 xl:gap-x-8"
            >
              {currentDirectory?.children?.map((child, idx) => (
                <MyContinuitiesCard
                  key={idx}
                  item={child}
                  onClick={handleItemClick}
                  onDelete={() => {
                    setFolderForDeletion(child)
                    setShowWarning(true)
                  }}
                />
              ))}
              {currentDirectory?.masters?.map((child, idx) => (
                <MyContinuitiesCard
                  key={idx}
                  item={child}
                  onClick={handleItemClick}
                  onDelete={handleDeleteMaster}
                />
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className="mt-6 w-full">
          <p className="text-center font-semibold">
            Sorry, this person doesn&apos;t have any saved continuities to show.
          </p>
          <EmptyState className="mx-auto h-64" />
        </div>
      )}
      <GenericModal
        show={showWarning}
        title="Warning"
        onClose={() => {
          setShowWarning(false)
          setFolderForDeletion(null)
        }}
        onAccept={handleDeleteFolder}
      >
        <div>
          Are you sure you want to delete {folderForDeletion?.name}? This will remove all it&apos;s
          contents and cannot be undone.
        </div>
      </GenericModal>
      {(isLoading || isContinuitiesLoading) && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
    </>
  )
}
