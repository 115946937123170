import { useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import { Link, useHistory } from 'react-router-dom'
// import { v4 as uuid } from 'uuid'

import { setFilters, setSearchTerm } from '../data/globals'
import { api } from '../services/api'

import { NavBar } from '../components/NavBar'
import ProgressiveImg from '../components/ProgressiveImage'
import { createFilterFromAttribute } from '../components/filters/utils'
import CategorySection from '../components/homepage/CategorySection'
import Footer from '../components/homepage/Footer'
import SaveCard from '../components/homepage/SaveCard'
import VipCard from '../components/homepage/VipCard'
import { SearchInput } from '../components/inputs/SearchInput'

import { AndOrType } from '../data/globals/types'
import { IProduct } from '../types/modelTypes'
import { resizeComputedImage } from '../utils/helpers'

export default function Home() {
  const alert = useAlert()
  const history = useHistory()

  const [assets, setAssets] = useState<Record<number, IProduct>>({})
  const search = useRef('')

  useEffect(() => {
    api.Products.getHomepageAssets()
      .then(({ data }) => {
        setAssets(data)
      })
      .catch((error) => {
        console.log(error)
        alert.error('Error fetching homepage assets')
      })
  }, [])

  function setFilterFor(product: IProduct) {
    // and tags and if exist, create filter in redux for them and navigate to products screen
    const newFilters = []

    if (product.vendor) {
      newFilters.push(createFilterFromAttribute('vendor', product.vendor, 'and'))
    }
    if (product.brand) {
      newFilters.push(createFilterFromAttribute('brand', product.brand, 'and'))
    }
    if (product.warehouse) {
      newFilters.push(createFilterFromAttribute('warehouse', 'Warehouse', 'and'))
    }
    if (product.exclusive) {
      newFilters.push(createFilterFromAttribute('exclusive', 'Exclusive', 'and'))
    }
    if (product.tags) {
      product.tags.split(',').forEach((t) => {
        newFilters.push(createFilterFromAttribute('tags', t, 'and'))
      })
    }

    setFilters(newFilters)
    history.push({
      pathname: '/products',
    })
  }

  async function handleSearch(term: string) {
    search.current = term
  }

  function handleSearchSubmit() {
    setSearchTerm(search.current)
    history.push({
      pathname: '/products',
    })
  }

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()

        handleSearchSubmit()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  function handleCategoryClick(catName: string) {
    const newFilters = [createFilterFromAttribute('category', catName, 'or')]
    setFilters(newFilters)
    history.push({
      pathname: '/products',
    })
  }

  function handleVipProductClick(prod?: IProduct) {
    if (!prod?.id) {
      // Show all products with vip filter
      showAllVipProducts()
    } else {
      // show product page
      history.push({
        pathname: `/products/${prod.id}`,
        state: {
          from: '/home',
        },
      })
    }
  }

  function showAllVipProducts() {
    const newFilters = [createFilterFromAttribute('tags', 'Vip', 'or')]
    setFilters(newFilters)
    history.push({
      pathname: '/products',
    })
  }

  function setRangeFilter(gte: number, lt?: number) {
    const ranges = []
    ranges.push(createFilterFromAttribute('cost', gte, 'gte', AndOrType.int, 'Is greater than'))
    if (lt) {
      ranges.push(createFilterFromAttribute('cost', lt, 'lte', AndOrType.int, 'Is less than'))
    }
    setFilters(ranges)
    history.push({
      pathname: '/products',
    })
  }

  function underFifteen() {
    setRangeFilter(0, 15)
  }

  function fifteenToTwentyFive() {
    setRangeFilter(15, 25)
  }

  function twentyFiveToFifty() {
    setRangeFilter(25, 50)
  }

  function overFifty() {
    setRangeFilter(50)
  }

  function handleSaveCardClick(catName: string) {
    if (catName) {
      const newFilters = [createFilterFromAttribute('category', catName, 'or')]
      setFilters(newFilters)
    }
    history.push({
      pathname: '/products',
    })
  }

  return (
    <div className="w-full bg-white">
      {/* Mobile menu */}
      <NavBar builder={false} />

      <div className={`fixed left-0 right-0 top-16 z-10 bg-purple-500 py-4`}>
        <div className="max-w-8xl flex w-full items-center px-8 sm:px-16">
          <div className="w-full md:w-1/2">
            <SearchInput className="block" onChange={handleSearch} />
          </div>
          <Link
            to={'/products'}
            className="ml-8 whitespace-nowrap text-center text-sm text-white hover:text-purple-100"
          >
            See All
          </Link>
        </div>
      </div>

      <div className="mx-auto mb-24 bg-white px-8 sm:px-16 md:w-3xl lg:w-5xl xl:w-7xl">
        {/* Hero section */}
        <section className="relative mt-20">
          <div className="flex flex-col">
            {/* Top row */}
            <div
              onClick={() => setFilterFor(assets[1])}
              className="mb-4 cursor-pointer md:h-[215px] lg:h-[310px] xl:h-[380px]"
            >
              <ProgressiveImg
                src={assets[1]?.primaryImage ?? undefined}
                alt={assets[1]?.name ?? ''}
                placeholderSrc={resizeComputedImage(assets[1]?.primaryImage, 70, 228)}
                className="md:h-[240px] lg:h-[345px] xl:h-[380px]"
              />
            </div>
            {/* bottom row */}
            <div className="flex h-full flex-col md:flex-row">
              {/* bottom row left */}
              <button
                onClick={() => setFilterFor(assets[2])}
                className="mb-4 flex-1 cursor-pointer sm:mb-0 md:mr-2"
              >
                <ProgressiveImg
                  src={assets[2]?.primaryImage ?? undefined}
                  alt={assets[2]?.name ?? ''}
                  placeholderSrc={resizeComputedImage(assets[2]?.primaryImage, 214, 312)}
                  className="object-cover md:h-[212px] lg:h-[300px] xl:h-[360px]"
                />
              </button>
              {/* bottom row right */}
              <div className="flex flex-1 flex-col justify-between md:ml-2">
                {/* bottom row right top */}
                <button
                  onClick={() => setFilterFor(assets[3])}
                  className="mb-2 mt-4 cursor-pointer md:mb-0 md:mt-0 md:h-[100px] lg:h-[140px] xl:h-[170px]"
                >
                  <ProgressiveImg
                    src={assets[3]?.primaryImage ?? undefined}
                    alt={assets[3]?.name ?? ''}
                    placeholderSrc={resizeComputedImage(assets[3]?.primaryImage, 170, 312)}
                    className="object-cover md:h-[100px] lg:h-[140px] xl:h-[170px]"
                  />
                </button>
                {/* bottom row right bottom */}
                <div
                  onClick={() => setFilterFor(assets[4])}
                  className="mt-2 cursor-pointer sm:mb-0 md:h-[100px] lg:h-[140px] xl:h-[170px]"
                >
                  <ProgressiveImg
                    src={assets[4]?.primaryImage ?? undefined}
                    alt={assets[4]?.name ?? ''}
                    placeholderSrc={resizeComputedImage(assets[4]?.primaryImage, 170, 312)}
                    className="object-cover md:h-[100px] lg:h-[140px] xl:h-[170px] "
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <main>
          {/* Category section */}
          <CategorySection
            title="POPULAR CATEGORIES"
            onCateogryClick={handleCategoryClick}
            categories={[assets[5], assets[6], assets[7], assets[8]]}
          />

          {/* By Budget section */}
          <section aria-labelledby="budget-heading" className="mx-auto px-4 pt-8 sm:px-0">
            <div className="relative overflow-hidden">
              <div className="relative bg-purple-500 px-4 py-4">
                <div className="relative flex flex-col items-center md:flex-row">
                  <h2
                    id="social-impact-heading"
                    className="text-center text-3xl font-bold tracking-tight text-white sm:text-4xl"
                  >
                    <span className="">SHOP BY BUDGET</span>
                  </h2>
                  <div className="grid min-h-32 w-full grid-cols-1 grid-rows-4 items-center justify-between md:grid-cols-4 md:grid-rows-1 md:gap-4">
                    <button
                      onClick={underFifteen}
                      className="my-2 flex h-16 w-full flex-1 items-center justify-center bg-black text-center text-white md:h-full"
                    >
                      UNDER $15
                    </button>
                    <button
                      onClick={fifteenToTwentyFive}
                      className="my-2 flex h-16 w-full flex-1 items-center justify-center bg-black text-center text-white md:h-full"
                    >
                      $15 - $25
                    </button>
                    <button
                      onClick={twentyFiveToFifty}
                      className="my-2 flex h-16 w-full flex-1 items-center justify-center bg-black text-center text-white md:h-full"
                    >
                      $25 - $50
                    </button>
                    <button
                      onClick={overFifty}
                      className="my-2 flex h-16 w-full flex-1 items-center justify-center bg-black text-center text-white md:h-full"
                    >
                      $50 AND UP
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* More categories section */}
          <CategorySection
            title="MORE CATEGORIES"
            onCateogryClick={handleCategoryClick}
            // categories={[assets[10], assets[11], assets[12], assets[13], assets[14]]}
            categories={[assets[10], assets[11], assets[12], assets[13]]}
          />

          {/* VIP Section */}
          <section aria-labelledby="vip-heading" className="mx-auto px-4 pt-8 sm:px-0">
            <div className="relative overflow-hidden">
              <div className="relative bg-purple-500 px-6 py-4 sm:px-8">
                <div className="relative flex flex-col items-center md:flex-row">
                  <h2
                    id="social-impact-heading"
                    className="text-center text-3xl font-bold tracking-tight text-white sm:text-4xl"
                  >
                    <span className="">VIP LEVEL GIFTS</span>
                  </h2>
                  <div className="scrollbar relative box-content h-60 w-full overflow-x-auto pt-2">
                    <div className="min-w-screen-xl absolute flex space-x-8 px-4 sm:px-6 lg:px-8">
                      <VipCard product={assets[15]} onClick={handleVipProductClick} />
                      <VipCard product={assets[16]} onClick={handleVipProductClick} />
                      <VipCard product={assets[17]} onClick={handleVipProductClick} />
                      <VipCard onClick={handleVipProductClick} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Save section */}
          <section
            aria-labelledby="collection-heading"
            className="mx-auto max-w-xl px-4 pt-16 sm:px-0 lg:max-w-7xl"
          >
            <div className="px-2 sm:flex sm:items-center sm:justify-between sm:px-0">
              <h2 id="collection-heading" className="text-2xl tracking-tight text-gray-900">
                MORE WAYS TO SHOP
              </h2>
              <Link
                to="/products"
                className="hidden text-sm font-semibold text-purple-500 hover:text-purple-300 sm:block"
              >
                BROWSE ALL PRODUCTS
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>

            <div className="mt-4 grid space-y-12 lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
              <SaveCard
                onClick={handleSaveCardClick}
                category={assets[19]?.category}
                imageSrc={assets[19]?.primaryImage}
              />
              <SaveCard
                onClick={handleSaveCardClick}
                category={assets[20]?.category}
                imageSrc={assets[20]?.primaryImage}
              />
              <SaveCard
                onClick={handleSaveCardClick}
                category={assets[21]?.category}
                imageSrc={assets[21]?.primaryImage}
              />
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  )
}
