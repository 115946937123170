import { Dialog, DialogTitle } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { Button } from 'components/small/Button'

interface IWarningModalProps {
  title: string
  content?: string
  show: boolean
  onClose?: () => void
  onCancel?: () => void
  onAccept?: () => void
  closeText?: string
}
export const WarningModal = ({
  title,
  content,
  show,
  onClose = () => {},
  onCancel = () => {},
  onAccept = () => {},
  closeText = 'Close',
}: IWarningModalProps) => {
  // The x icon
  function handleClose() {
    onClose()
  }

  // The GO button
  function handleAccept() {
    onAccept()
  }

  // The No GO button
  function handleCancel() {
    onCancel()
  }

  return (
    <Dialog
      as="div"
      transition
      className="fixed inset-0 flex w-screen items-center justify-center bg-gray-500/75 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
      open={show}
      onClose={handleClose}
    >
      <div className="transition-all inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </DialogTitle>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{content}</p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
          <Button onClick={handleAccept} variant="warning">
            OK
          </Button>
          <Button onClick={handleCancel} variant="outline">
            {closeText}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
