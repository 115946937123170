interface IProductTagsListProps {
  tags: string
  onClick: (tag: string) => void
}
export const ProductTagsList = ({ tags, onClick }: IProductTagsListProps) => {
  return (
    <section className="mt-auto flex flex-wrap items-end">
      {tags.split(',').map((tag, idx) => (
        <button
          key={idx}
          className="mr-2 cursor-pointer rounded-full border-2 border-purple-300 text-xs text-purple-300 hover:bg-gray-100"
          style={{ padding: '4px 12px', marginBottom: '2px' }}
          onClick={() => onClick(tag)}
        >
          {tag}
        </button>
      ))}
    </section>
  )
}
