import { useEffect, useState } from 'react'

import { LoadingSpinner } from 'components/LoadingSpinner'
import { TextInput } from 'components/inputs/TextInput'
import { Directory } from 'components/tree/Directory'
import { IDirectoryCrumb } from 'data/globals/types'
import { useAppSelector } from 'data/reduxHooks'
import { useAlert } from 'react-alert'
import { GenericModal } from './GenericModal'

interface IAddFileModalProps {
  title: string
  show: boolean
  loading?: boolean
  onClose: () => void
  onAccept: (fileName: string, currentDir: IDirectoryCrumb) => void
}
export default function AddFileModal({
  title,
  show,
  loading,
  onClose = () => {},
  onAccept,
}: IAddFileModalProps) {
  const alert = useAlert()
  const deck = useAppSelector((state) => state.decks)

  const [fileName, setFileName] = useState('')
  const [currentDir, setCurrentDir] = useState<IDirectoryCrumb | null>(null)

  useEffect(() => {
    setFileName(deck.filename)
  }, [deck])

  function handleAccept() {
    if (fileName && currentDir) {
      onAccept(fileName, currentDir)
    } else {
      alert.error('Please enter a file name and select a directory')
    }
  }

  return (
    <GenericModal
      fullWidth
      title={title}
      loading={loading}
      show={show}
      onClose={onClose}
      onAccept={handleAccept}
    >
      <div className="flex flex-col h-full w-full">
        {/* Directory takes all available height */}
        <div className="flex-1">
          <Directory onDirChange={setCurrentDir} />
        </div>
        {/* TextInput at the bottom if applicable */}
        {currentDir && currentDir.level !== 'users' && currentDir.level !== 'user' && (
          <div className="mt-4">
            <TextInput
              srOnly={false}
              label="File Name"
              onChange={setFileName}
              placeholder="File name"
              value={fileName}
            />
          </div>
        )}
      </div>

      {/* Loading Spinner Overlay */}
      {loading && (
        <div className="absolute top-0 right-0 left-0 bottom-0 z-30 flex items-center justify-center bg-white opacity-70">
          <LoadingSpinner />
        </div>
      )}
    </GenericModal>
  )
}
