import { useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'

// Components
import { LoadingSpinner } from 'components/LoadingSpinner'
import { MasterSlideCard } from 'components/MasterSlideCard'
import { SearchInput } from 'components/inputs/SearchInput'
import { WarningModal } from 'components/modals/WarningModal'

// Data
import { addMasterToSlide } from 'data/decks'
import { api } from 'services/api'

// Types
import { IContinuity } from 'types/modelTypes'

interface IMasterSlidesViewProps {
  onAddComplete: () => void
  builder?: boolean
}
export default function MasterSlidesView({
  onAddComplete,
  builder = false,
}: IMasterSlidesViewProps) {
  const alert = useAlert()

  const isSelected = false // derived from state of multi select
  const selectedContintuities: any[] = [] // pull from state of multi select
  const sort = { order_by: 'updatedAt', order: 'DESC' } // add sort dropdown and persist in state

  const [items, setItems] = useState<IContinuity[]>([])
  const [showWarning, setShowWarning] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const tempSlide = useRef<IContinuity | null>(null)

  useEffect(() => {
    setIsLoading(true)
    api.Directory.searchMasters({
      search: searchTerm.trim(),
      order_by: 'updatedAt',
      order: 'DESC',
    })
      .then(({ data }) => {
        setItems(data)
      })
      .catch((err) => alert.error(err.message))
      .finally(() => setIsLoading(false))
  }, [alert, searchTerm])

  function handleAddSlide(slide: IContinuity) {
    tempSlide.current = slide
    setShowWarning(true)
  }

  function onAcceptWarning() {
    setShowWarning(false)
    if (tempSlide.current) {
      addMasterToSlide(tempSlide.current)
    }
    tempSlide.current = null
    onAddComplete()
  }

  function onClose() {
    setShowWarning(false)
    tempSlide.current = null
    onAddComplete()
  }

  const handleSearch = (term: string) => {
    setSearchTerm(term)
  }

  function handleSlideDelete(slideId: string) {
    setItems((prev) => prev.filter((slide) => slide.id != slideId))
  }

  return (
    <div className="max-w-8xl relative mx-auto">
      <div className="flex w-full items-center justify-between py-6 sm:w-1/2">
        <SearchInput className="w-full" onChange={handleSearch} useCache={false} />
      </div>
      <div className="scrollbar grid grid-cols-1 gap-x-4 gap-y-8 overflow-y-scroll px-4 pb-6 pt-2 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 2xl:grid-cols-6">
        {items.map((cont, idx) => {
          return (
            <MasterSlideCard
              key={idx}
              builder={builder}
              continuity={cont}
              onAddSlide={handleAddSlide}
              selected={selectedContintuities}
              isSelected={isSelected}
              sortParams={sort}
              onDelete={handleSlideDelete}
            />
          )
        })}
      </div>
      <WarningModal
        onAccept={onAcceptWarning}
        onClose={onClose}
        onCancel={onClose}
        title="Warning"
        content="This may replace your current slide. Continue?"
        show={showWarning}
      />
      {isLoading && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}
