import { TabPanel } from '@headlessui/react'
import throttle from 'lodash.throttle'
import { useCallback, useState } from 'react'

import { BudgetInfo } from 'components/continuityBuilder/BudgetInfo'
import { GenericSelectMenuOption } from 'components/continuityBuilder/Controls'
import { ControlsPriceModifier } from 'components/continuityBuilder/Controls--PriceModifier'
import { SlideBackgroundSelector } from 'components/continuityBuilder/SlideBackgroundSelector'
import { SlideOverlaySelector } from 'components/continuityBuilder/SlideOverlaySelector'
import { RangeSlider } from 'components/inputs/RangeSlider'
import { SelectMenu } from 'components/inputs/SelectMenu'
import { TextInput } from 'components/inputs/TextInput'
import { WarningModal } from 'components/modals/WarningModal'
import { Button } from 'components/small/Button'
import { deleteSlide, duplicateSlide, updateCurrentSlide, updateCurrentSlideType } from 'data/decks'
import { ContinuityType, IContinuity, SlideLayoutType } from 'types/modelTypes'
import constants from 'utils/constants'
import { captureSlide } from 'utils/helpers'

interface IControlsSlideTabProps {
  slide: IContinuity
}
export const ControlsSlideTab = ({ slide }: IControlsSlideTabProps) => {
  const [showWarning, setShowWarning] = useState(false)

  function setCurrentSlideType(opt: GenericSelectMenuOption<string>) {
    updateCurrentSlideType({ type: opt.value })
    captureSlide()
  }

  function setCurrentSlideLayout(opt: GenericSelectMenuOption<SlideLayoutType>) {
    updateCurrentSlide({ layout: opt.value })
    captureSlide()
  }

  const throttledCaptureSlide = useCallback(throttle(captureSlide, 2000), [])

  function handleSlideTitleChange(val: string) {
    updateCurrentSlide({ title: val })
    throttledCaptureSlide()
  }

  function handleSlideQuantityChange(val: string) {
    updateCurrentSlide({ quantity: val })
  }

  function handleSlideOpacityChange(val: number) {
    updateCurrentSlide({ backgroundOpacity: val })
    captureSlide()
  }

  function onAcceptWarning() {
    deleteSlide()
    setShowWarning(false)
  }

  function onModalClose() {
    setShowWarning(false)
  }

  return (
    <>
      <TabPanel
        style={{ height: '100%' }}
        className="flex flex-col bg-white focus:outline-none focus:ring-0"
      >
        <div className="flex w-5/6 flex-grow flex-col">
          <TextInput
            label="Title of Slide"
            srOnly={false}
            onChange={handleSlideTitleChange}
            value={slide.title || ''}
            containerClass="mb-5"
          />
          <SelectMenu
            className="mb-5"
            label="Slide Type"
            options={constants.SLIDE_TYPE_OPTIONS}
            onChange={setCurrentSlideType}
            value={
              constants.SLIDE_TYPE_OPTIONS.find((opt) => opt.value === slide.type) ||
              constants.SLIDE_TYPE_OPTIONS[0]
            }
          />
          <SelectMenu
            label="Layout"
            className="mb-[10px]"
            options={constants.SLIDE_LAYOUT_OPTIONS}
            disabled={slide.type === ContinuityType.quad_horizontal}
            onChange={setCurrentSlideLayout}
            value={
              constants.SLIDE_LAYOUT_OPTIONS.find((opt) => opt.value === slide.layout) ||
              constants.SLIDE_LAYOUT_OPTIONS[0]
            }
          />
          <div className="mb-[10px]">
            {slide.layout === SlideLayoutType.BACKGROUND && <SlideBackgroundSelector />}
            {slide.layout === SlideLayoutType.OVERLAY && <SlideOverlaySelector />}
          </div>
          {slide.layout === SlideLayoutType.BACKGROUND && (
            <div className="mb-[14px]">
              <RangeSlider label="Background Opacity" onChange={handleSlideOpacityChange} />
            </div>
          )}
          <ControlsPriceModifier
            label="Slide Price Modifier"
            onChange={(val) => updateCurrentSlide({ priceModifier: val })}
            defaultModifier={slide.priceModifier}
          />
          {slide.type !== ContinuityType.single && (
            <BudgetInfo priceModifier={Number(slide.priceModifier)} />
          )}
          <TextInput
            label="Quantity"
            srOnly={false}
            onChange={handleSlideQuantityChange}
            value={slide.quantity}
            containerClass="mb-5"
            type="number"
          />
        </div>
        <div className="flex flex-col items-center justify-end">
          <Button onClick={duplicateSlide} className="mb-2 w-5/6" variant="primary" size="s">
            Duplicate Slide
          </Button>
          <Button onClick={() => setShowWarning(true)} variant="warning" size="s" className="w-5/6">
            Delete Slide
          </Button>
        </div>
      </TabPanel>
      <WarningModal
        onAccept={onAcceptWarning}
        onClose={onModalClose}
        onCancel={onModalClose}
        title="Warning"
        content="Are you sure you want to delete this slide?"
        show={showWarning}
      />
    </>
  )
}
