import { useState } from 'react'

interface IRangeSliderProps {
  onChange: (value: number) => void
  label?: string
}
export const RangeSlider = ({ onChange, label }: IRangeSliderProps) => {
  const [value, setValue] = useState(100) // Initial value

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value))
  }

  const handleMouseUp = () => {
    onChange(value / 100)
  }

  return (
    <div className="flex flex-col space-y-1 w-full max-w-sm mx-auto">
      {label && (
        <label htmlFor="range-slider" className="block text-sm font-semibold">
          {label}
        </label>
      )}
      <input
        id="range-slider"
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleChange}
        onPointerUp={handleMouseUp}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-none focus:ring-none [&::-webkit-slider-thumb]:bg-purple-500 accent-purple-500"
      />
    </div>
  )
}
