import Select, { GroupBase, Props } from 'react-select'

export default function AdvancedFilterSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  return (
    <Select
      {...props}
      className={`basic-single ${props.className}`}
      classNamePrefix="select"
      classNames={{
        menuPortal: () => 'scrollbar',
      }}
      styles={{
        // menu: ({ left, top, ...provided }, state) => ({
        //   ...provided,
        //   zIndex: 9999,
        // }),
        // menuPortal: ({ left, top, ...provided }, state) => ({
        //   ...provided,
        //   // zIndex: 9999,
        // }),
        control: ({ left, top, ...provided }, state) => ({
          ...provided,
          borderRadius: 0,
        }),
      }}
      isRtl={false}
      // name={name}
      menuPlacement="bottom"
      menuPosition="fixed"
      menuShouldScrollIntoView={false}
    />
  )
}
