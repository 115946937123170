import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { selectCurrentSlide } from '../data/decks/selectors'
import { updateSelectedProducts } from '../data/selections'
import { selectedProducts } from '../data/selections/selectors'
import { selectIsAdmin, selectOrganizationMarkup, selectUser } from '../data/user/selectors'
import { api } from '../services/api'

import { MultiSelectBar } from './MultiSelectBar'
import { BackButton } from './buttons/BackButton'
import ProductCardGrid from './productsView/ProductCardGrid'

// import { ReactComponent as EmptyState } from '../assets/svgs/empty_state.svg'
import { selectFilterString } from 'data/globals/selectors'
import { ISortOption } from 'data/globals/types'
import { useAppSelector } from 'data/reduxHooks'
import { useErrorAlert } from 'hooks/useErrorAlert'
import { IProduct, IUser } from 'types/modelTypes'
import { setCurrentList } from '../data/globals'
import { MenuGeneric } from './MenuGeneric'
import { ProductSelectionType } from './ProductsView'

const SORT_OPTIONS = [
  { id: 1, name: 'Recently added', value: 'updatedAt', desc: true },
  { id: 2, name: 'Cost - Low-High', value: 'cost', desc: false },
  { id: 3, name: 'Cost - High-Low', value: 'cost', desc: true },
  { id: 4, name: 'Inventory - High-Low', value: 'inventory', desc: true },
  { id: 5, name: 'Inventory - Low-High', value: 'inventory', desc: false },
]

interface IMyListsTabDetailProps {
  slot: number | null
  onAddComplete: () => void
  user?: IUser
}
export function MyListsTabDetail({ slot, onAddComplete, user }: IMyListsTabDetailProps) {
  const { errorAlert } = useErrorAlert()
  const params = useParams<{ listId: string }>()
  const history = useHistory()
  const location = useLocation<{ sortState: ISortOption }>()

  const me = useAppSelector(selectUser)
  const selected = useAppSelector(selectedProducts)
  const isAdmin = useAppSelector(selectIsAdmin)
  const favorites = useAppSelector((state) => state.favorites)
  const { currentSlide } = useAppSelector(selectCurrentSlide)
  const orgMarkup = useAppSelector(selectOrganizationMarkup)
  const currentList = useAppSelector((state) => state.globals.currentList)
  const filterString = useAppSelector(selectFilterString)

  const [sort, setSort] = useState<ISortOption>(SORT_OPTIONS[0])
  const [reload, setReload] = useState(false)
  const [sortedList, setSortedList] = useState<IProduct[]>([])

  const { listId } = params
  const { sortState } = location.state || {}

  useEffect(() => {
    if (sortState) {
      setSort(sortState)
    }
  }, [sortState])

  useEffect(() => {
    if (!listId) {
      setCurrentList(null)
      history.goBack()
    }
  }, [listId, history])

  function handleBack() {
    setCurrentList(null)
    history.goBack()
  }

  function onDelete() {
    setReload(!reload)
  }

  function handleSelect(data: ProductSelectionType) {
    if (data.add) {
      updateSelectedProducts({
        ...selected,
        [data.id]: data.product,
        // [data.id]: {
        //   // we add a source here so we know which items to bulk delete
        //   // from the multi-select bar
        //   source: 'list',
        //   ...data.product,
        // },
      })
    } else {
      const updated = { ...selected }
      delete updated[data.id]
      updateSelectedProducts(updated)
    }
  }

  function handleSelectAll() {
    const newSelected: Record<number, IProduct> = {}
    currentList?.products?.forEach((p) => {
      newSelected[p.id] = p
    })
    updateSelectedProducts(newSelected)
  }

  const fetchMyLists = useCallback(async () => {
    if (!user?.id || !listId) {
      return []
    }
    const { data } = await api.List.getById(Number(listId), {
      user_id: user?.id ?? me?.id,
      filter: filterString.length > 0 ? filterString.join('_') : '',
      order: sort.desc ? 'DESC' : 'ASC',
      order_by: sort.value,
    })

    setCurrentList(data)
    if (data.products) {
      setSortedList(data.products)
    }
    return data
  }, [user?.id, me, sort, listId])

  const { error } = useQuery(['lists', sort, reload], fetchMyLists, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (error) {
      errorAlert(error, 'Error loading lists')
    }
  }, [error])

  // function handleShowDetails(slot, id) {}

  function handleDeleteAllFromList() {
    setReload(!reload)
  }

  if (!currentList) {
    return null
  }

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="sticky top-0 z-10 flex items-center bg-white" style={{ minHeight: '54px' }}>
          <BackButton onClick={handleBack} text="Back to Lists" />
          <h3 className="ml-12">My Lists / {currentList.name}</h3>
          <div className="ml-auto">
            <MenuGeneric options={SORT_OPTIONS} value={sort} onChange={setSort} />
          </div>
        </div>
        <ProductCardGrid
          data={sortedList}
          selected={selected}
          onSelect={handleSelect}
          favorites={favorites}
          isAdmin={isAdmin}
          slot={slot}
          fromList={true}
          currentList={currentList}
          onAddComplete={onAddComplete}
          // onShowDetails={handleShowDetails}
          currentSlide={currentSlide}
          orgMarkup={orgMarkup}
          onDelete={onDelete}
          sortParams={sort}
        />
      </div>
      {me && (
        <MultiSelectBar
          onSelectAll={handleSelectAll}
          user={me}
          onDeleteAllFromList={handleDeleteAllFromList}
        />
      )}
    </>
  )
}
