import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useQuery } from 'react-query'

import { deleteUser, disableUser } from 'services/admin'
import { api } from 'services/api'
import constants from 'utils/constants'

import { LoadingSpinner } from 'components/LoadingSpinner'
import { Button } from 'components/small/Button'
import { NewUserModal } from '../admin/NewUserModal'
import { SearchInput } from '../inputs/SearchInput'
import { UserListItem } from './UserListItem'

export const MembersTab = ({ status = constants.USER_STATUS.ACTIVE, role }) => {
  const alert = useAlert()

  const [showModal, setShowModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [forceFetch, setForceFetch] = useState(false)

  const fetchUsersWithFavorites = async () => {
    const { data } = await api.User.getAll({
      withFavorites: true,
      withOrganizations: true,
      searchTerm,
      status: status,
      role,
    })
    return data
  }

  const { isLoading, error, data } = useQuery(
    ['people', searchTerm, forceFetch],
    fetchUsersWithFavorites,
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (error) {
      alert.error(error.message)
    }
  }, [error, alert])

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  function handleManageClick(user) {
    setEditMode(true)
    setShowModal(true)
    setSelectedUser(user)
  }

  function handleModalClose() {
    setSelectedUser(null)
    setEditMode(false)
    setShowModal(false)
  }

  function handleAddComplete() {
    setSelectedUser(null)
    handleModalClose()
    setForceFetch(!forceFetch)
  }

  async function handleDisable(user) {
    try {
      if (user) {
        await disableUser(user.uuid)
        await api.User.softDelete(user.id)
        setForceFetch(!forceFetch)
        alert.success('User disabled')
      }
    } catch (error) {
      alert.error(error.message)
    }
  }

  async function handleEnable() {
    try {
      if (selectedUser) {
        await disableUser(selectedUser.uuid)
        setForceFetch(!forceFetch)
        alert.success('User disabled')
      }
    } catch (error) {
      alert.error(error.message)
    }
  }

  async function handleDelete(user) {
    try {
      if (user) {
        await deleteUser(user.uuid)
        await api.User.delete(user.id)
        setForceFetch(!forceFetch)
        alert.success('User deleted')
      }
    } catch (error) {
      alert.error(error.message)
    }
  }

  return (
    <div className="mx-auto flex h-full w-full flex-col overflow-hidden pb-8">
      <div className="flex w-full items-center justify-between py-6">
        <SearchInput className="mr-2 w-full sm:mr-0 sm:w-1/2" onChange={handleSearch} />
        <Button onClick={() => setShowModal(true)}>Invite</Button>
      </div>
      <div className="h-full">
        <ul role="list" className="my-4 min-h-32 space-y-3 divide-y">
          {data?.map((u, personIdx) => (
            <UserListItem
              user={u}
              key={personIdx}
              onManageClick={handleManageClick}
              onDisable={handleDisable}
              onDelete={handleDelete}
              allowReinvite={status === constants.USER_STATUS.INVITED}
            />
          ))}
        </ul>
      </div>
      {showModal && (
        <NewUserModal
          open={showModal}
          editMode={editMode}
          onClose={handleModalClose}
          onAddComplete={handleAddComplete}
          user={selectedUser}
          onDelete={() => {
            // handleDelete()
          }}
        />
      )}
      {isLoading && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}
