import { ChevronRightIcon, DocumentIcon, FolderIcon } from '@heroicons/react/solid'
import { IContinuityMaster, IDirectory } from 'types/modelTypes'

interface IDirectoryListProps {
  folders: IDirectory[]
  files: IContinuityMaster[]
  onFolderClick: (item: IDirectory) => void
  onFileClick: (item: IContinuityMaster) => void
}
export default function DirectoryList({
  folders = [],
  files,
  onFolderClick,
  onFileClick,
}: IDirectoryListProps) {
  return (
    <div className="w-full overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {folders.map((item, idx) => (
          <li key={idx} className="w-full">
            <button
              onClick={() => onFolderClick(item)}
              className="flex w-full outline-none hover:bg-gray-50 focus:outline-none"
            >
              <div className="flex w-full items-center justify-between px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                    <FolderIcon className="h-8 w-8 text-gray-500" />
                  </div>
                  <div className="min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <p className="truncate text-sm font-medium text-black">{item.name}</p>
                  </div>
                </div>
                <div className="ml-auto">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </button>
          </li>
        ))}
        {files.map((item, idx) => (
          <li key={idx} className="w-full">
            <button
              onClick={() => onFileClick(item)}
              className="flex w-full outline-none hover:bg-gray-50 focus:outline-none"
            >
              <div className="flex w-full items-center justify-between px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                    <DocumentIcon className="h-8 w-8 text-gray-500" />
                  </div>
                  <div className="min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <p className="truncate text-sm font-medium text-black">{item.filename}</p>
                  </div>
                </div>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
