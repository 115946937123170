import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import { IContinuity, SlideLayoutType } from 'types/modelTypes'
import { captureSlide, classNames } from 'utils/helpers'

interface IProductSelectorBackgroundProps {
  slide: IContinuity
  children: React.ReactNode
}
export default function ProductSelectorBackground({
  slide,
  children,
}: IProductSelectorBackgroundProps) {
  const { ref } = useResizeObserver({
    onResize: ({ height }) => {
      setHeight(height)
    },
  })

  const [height, setHeight] = useState<number>()
  const [background, setBackground] = useState<{ url: string } | null>(null)
  const [overlayVisible, setOverlayVisible] = useState(true)

  useEffect(() => {
    if (slide.backgroundImage) {
      setBackground({ url: slide.backgroundImage })
    }
  }, [slide])

  useEffect(() => {
    captureSlide()
  }, [overlayVisible])

  return (
    <div
      ref={ref}
      className={classNames('relative h-full', 'hover:bg-gray-200')}
      style={{
        width: height + 'px',
        minWidth: height + 'px',
      }}
    >
      {/* Background using ::before */}
      <div
        className="absolute inset-0 pointer-events-none before:absolute before:inset-0 before:bg-cover before:bg-center before:bg-no-repeat"
        style={{
          backgroundImage:
            slide.layout === SlideLayoutType.BACKGROUND ? `url(${background?.url ?? ''})` : '',
          opacity: slide.backgroundOpacity ?? 1, // Dynamic opacity
        }}
      />

      {/* Content container */}
      <div
        className="relative h-full w-full"
        style={{
          padding:
            slide.layout === SlideLayoutType.BORDERED || slide.layout === SlideLayoutType.BACKGROUND
              ? '5%'
              : 0,
        }}
      >
        {children}
      </div>

      {/* Overlay logic */}
      {slide.layout === SlideLayoutType.OVERLAY ? (
        <>
          <div>
            {overlayVisible && slide.overlayImage ? (
              <div className="absolute inset-0 z-10 h-full w-full">
                <img src={slide.overlayImage} className="h-full w-full object-cover" />
              </div>
            ) : null}
            <div
              onClick={() => setOverlayVisible((s) => !s)}
              className="absolute z-20 top-3 right-3 cursor-pointer flex flex-col items-center hover:text-gray-400"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                padding: '8px',
              }}
            >
              {overlayVisible ? (
                <EyeOffIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-500" />
              )}
              <span className="text-3xs text-gray-500">Toggle Overlay</span>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}
