import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'

import MasterSlidesView from '../MasterSlidesView'
import { MyListsTab } from '../MyListsTab'
import ProductsView from '../ProductsView'
import { GenericModal } from './GenericModal'

import { classNames } from '../../utils/helpers'

interface IAddProductModalProps {
  title?: string
  show: boolean
  onClose: () => void
  slot: number
  fromNotes?: boolean
  defaultIndex?: number
}
export default function AddProductModal({
  title,
  show,
  onClose,
  slot,
  fromNotes,
  defaultIndex = 0,
}: IAddProductModalProps) {
  function handleAddComplete() {
    onClose()
  }

  return (
    <GenericModal
      title={title || 'Add Product'}
      show={show}
      onClose={handleAddComplete}
      showButtons={false}
      fullWidth={true}
    >
      <TabGroup defaultIndex={defaultIndex}>
        <TabList className="flex space-x-1 rounded-xl p-1">
          {['My Lists', 'Catalog', 'Master Slides'].map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'whitespace-nowrap border-b-2 px-1 py-2 text-sm font-medium outline-none ring-0 focus:outline-none',
                  selected
                    ? 'border-purple-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                )
              }
            >
              {category}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="h-full">
          <TabPanel className="h-full bg-white focus:outline-none focus:ring-0">
            <div className="relative h-[66vh] overflow-y-scroll">
              <MyListsTab slot={slot} onAddComplete={handleAddComplete} />
            </div>
          </TabPanel>
          <TabPanel className="h-full bg-white focus:outline-none focus:ring-0">
            <div className="relative h-[66vh] overflow-y-scroll">
              <ProductsView
                slot={slot}
                fromNotes={fromNotes}
                onAddComplete={handleAddComplete}
                useCache={true}
                builder={true}
              />
            </div>
          </TabPanel>
          <TabPanel className="h-full bg-white focus:outline-none focus:ring-0">
            <div className="relative h-[66vh] overflow-y-scroll">
              <MasterSlidesView builder={true} onAddComplete={handleAddComplete} />
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </GenericModal>
  )
}
