import { IAdminFilter } from 'data/globals/types'
import { ProductFilters } from '../ProductFilters'

interface IClientFiltersProps {
  filters: IAdminFilter[]
  setFilters: (filters: IAdminFilter[]) => void
  onClear: () => void
  exclusivesOnly: boolean
}
export default function ClientFilters({
  filters,
  setFilters,
  onClear,
  exclusivesOnly,
}: IClientFiltersProps) {
  return (
    <div className="sticky top-8 overflow-hidden" style={{ height: '86vh', width: '200px' }}>
      <div className="mt-6 flex items-center justify-between">
        <p className="font-semibold">
          Filters {`${filters.length > 0 ? `(${filters.length})` : ''}`}
        </p>
        {filters.length > 0 && (
          <button
            onClick={onClear}
            className="cursor-pointer text-sm text-gray-400 outline-none ring-0 hover:text-gray-800 focus:outline-none focus:ring-0"
          >
            Clear All
          </button>
        )}
      </div>
      <div
        style={{ height: '79vh' }}
        className="scrollbar scrollbar relative overflow-y-scroll overscroll-contain pr-3"
      >
        <ProductFilters
          filters={filters}
          setFilters={setFilters}
          showExclusivesOnly={exclusivesOnly}
        />
      </div>
    </div>
  )
}
