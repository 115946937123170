import { forwardRef, memo } from 'react'

import { ProductCard } from '../ProductCard'

import { ProductSelectionType } from 'components/ProductsView'
import { ISortOption } from 'data/globals/types'
import { IProduct } from 'types/modelTypes'

interface IProductCardGridProps {
  data: IProduct[]
  isAdmin?: boolean
  favorites?: any
  showFavorites?: boolean
  // onBulkAdd?: (id: string) => void
  onBrandClick?: (brand: string) => void
  onVendorClick?: (vendor: string) => void
  onSelect: (props: ProductSelectionType) => void
  selected?: any
  page?: number
  slot?: number | null
  onAddComplete: () => void
  onShowDetails?: (slot: number, id: number) => void
  currentSlide?: any
  orgMarkup: any
  currentList?: any
  sortParams: ISortOption
  onDelete?: () => void
  fromNotes?: boolean
  fromList?: boolean
}
const ProductCardGrid = forwardRef<HTMLUListElement, IProductCardGridProps>(
  ({ data, selected, ...rest }, ref) => {
    return (
      <ul
        role="list"
        ref={ref}
        className="scrollbar grid grid-cols-1 gap-x-4 gap-y-8 overflow-y-scroll px-4 pb-6 pt-2 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 2xl:grid-cols-5"
      >
        {data?.map((prod, i) => (
          <ProductCard
            key={`${prod.id}_${i}`}
            id={`restore-${prod.id}`}
            isSelected={selected[prod.id] !== undefined}
            product={prod}
            {...rest}
          />
        ))}
      </ul>
    )
  },
)

ProductCardGrid.displayName = 'ProductCardGrid'

export default memo(ProductCardGrid)
