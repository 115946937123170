import { useSelector } from 'react-redux'

// Components
import { MultiSelectBar } from 'components/MultiSelectBar'
import { Checkbox } from 'components/inputs/Checkbox'
import { Button } from 'components/small/Button'

import { dateStringFormat, formatter } from 'utils/helpers'

// Data
import { updateSelectedSlides } from 'data/selections'
import { selectSelectedSlides } from 'data/selections/selectors'
import { selectIsAdmin, selectUser } from 'data/user/selectors'

// Types
import { ContinuityType, IContinuity } from 'types/modelTypes'

interface ISlideDetailViewProps {
  continuity: IContinuity
  fromBuilder?: boolean
  onAddComplete?: (slide: IContinuity) => void
}
export const SlideDetailView = ({
  continuity,
  fromBuilder,
  onAddComplete,
}: ISlideDetailViewProps) => {
  const user = useSelector(selectUser)
  const isAdmin = useSelector(selectIsAdmin)
  const selected = useSelector(selectSelectedSlides)

  function onCheckboxChange(val: boolean) {
    if (continuity.id === null) return
    if (val) {
      updateSelectedSlides({
        ...selected,
        [continuity.id]: continuity,
      })
    } else {
      const updated = { ...selected }
      delete updated[Number(continuity.id)]
      updateSelectedSlides(updated)
    }
  }

  const handleAddToDeck = () => {
    onAddComplete?.(continuity)
  }

  return continuity ? (
    <div className="mx-auto flex w-full flex-col">
      <div className="mx-auto px-4 py-8 sm:py-4">
        <div className="w-[400px] sm:w-[500px] md:grid md:w-[840px] md:grid-cols-2 md:items-start md:gap-x-8 lg:w-[1180px]">
          {/* Image */}
          <div className="flex min-w-[400px] flex-col-reverse">
            <div className="focus-within:none aspect-h-4 aspect-w-4 flex w-full cursor-pointer overflow-hidden bg-white outline-none focus:outline-none">
              <img
                src={continuity.thumbnail || ''}
                className="h-full w-full cursor-pointer object-contain object-center shadow-full-light"
                style={{
                  minWidth: continuity.type === ContinuityType.quad_horizontal ? undefined : '177%',
                }}
              />
            </div>
          </div>

          {/* Product info */}
          <div className="flex h-full flex-grow flex-col px-4 py-6 sm:px-0 md:py-0">
            {/* Vendor, brand, productId and updated date */}
            <div className="min-h-8 mb-2 flex flex-col text-sm">
              <div className="mr-4 cursor-pointer text-purple-500">
                {continuity.master?.title && isAdmin && (
                  <button className="hover:opacity-50">{continuity.master.title}</button>
                )}
              </div>
              Updated: {dateStringFormat(continuity.updatedAt)}
            </div>
            {/* title and checkboxes */}
            <div className="flex items-start justify-between">
              <h1 className="mr-4 line-clamp-2 text-3xl font-extrabold tracking-tight text-gray-900">
                {continuity.title}
              </h1>
              <div className="ml-4 flex items-center">
                {isAdmin && continuity.id !== null && !fromBuilder && (
                  <Checkbox
                    size={5}
                    id={continuity.id.toString()}
                    containerStyle="mr-2"
                    checked={selected[Number(continuity.id)] !== undefined}
                    // checked={false}
                    onChange={onCheckboxChange}
                  />
                )}
                {isAdmin && (
                  <Button onClick={handleAddToDeck} size="xs">
                    Add To Deck
                  </Button>
                )}
                {/* {favorites?.includes(continuity.id) ? (
                      <HeartFilled
                        onClick={() => handleUnfavorite(product)}
                        className="h-6 w-6 cursor-pointer"
                      />
                    ) : (
                      <Heart
                        onClick={() => handleFavorite(product)}
                        className="h-6 w-6 cursor-pointer"
                      />
                    )} */}
              </div>
            </div>

            <div className="mt-3 flex justify-between">
              <h2 className="sr-only">Product cost</h2>
              <div>
                <p className="text-xl font-extrabold tracking-tight text-gray-900">
                  {formatter.format(continuity.price / 100)}
                </p>
                <p className="text-sm text-gray-900">+ SHIPPING PER WEEK</p>
              </div>
            </div>

            <section aria-labelledby="details-heading" className="mt-3 flex flex-col">
              {/* Divider */}
              <div className="divide-gray-200 border-t text-gray-700" />

              {/* Description */}
              {continuity.products.map((product) => (
                <div key={product.id} className="mb-4">
                  <div className="mt-3">
                    <h3 className="sr-only">{product.name}</h3>
                    <div className="line-clamp-2 text-base font-bold text-gray-900">
                      {product.name || 'No Name found'}
                    </div>
                  </div>
                  {isAdmin ? (
                    <div>
                      <div className="text-base font-bold text-gray-900">
                        {formatter.format(product.cost)}
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-1">
                    <div className="text-base text-gray-500">
                      {`${product.brand} / ${
                        product.vendor ? product.vendor : 'No Vendor'
                      } / Item #${product.id}`}
                    </div>
                  </div>
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
      {user ? <MultiSelectBar user={user} /> : null}
    </div>
  ) : null
}
