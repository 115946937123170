import { format } from 'date-fns'
import pptxgenjs from 'pptxgenjs'
import { useEffect, useMemo } from 'react'

import constants from 'utils/constants'
import {
  formatter,
  generatePowerpointNotes,
  interpolateOpacityToTransparency,
  resizeComputedImage,
  totalContinuityPrice,
} from 'utils/helpers'

import { useAppSelector } from 'data/reduxHooks'
import { selectIsAdmin } from 'data/user/selectors'

// import titleSlideBackground from '../../assets/images/gps_title_slide.png'
// import titleSlideBlankBackground from '../../assets/images/gps_title_slide_blank.jpg'
import titleSlideBlackBackground from 'assets/images/gps_title_slide_black.png'
import { Button } from 'components/small/Button'
import { ContinuityType, IContinuity, IProduct } from 'types/modelTypes'

// const fourWeekContTitle = 'https://gps-solutions-assets.s3.us-west-2.amazonaws.com/four_week_cont_title.png',
// const shipping = 'https://gps-solutions-assets.s3.us-west-2.amazonaws.com/shipping.png',
// const shippingPerWeek = 'https://gps-solutions-assets.s3.us-west-2.amazonaws.com/shipping_week.png',
export const AVENIR_NEXT_LT_PRO = 'Avenir Next LT Pro'
export const AVENIR_NEXT_LT_PRO_LIGHT = 'Avenir Next LT Pro Light'
const imageSize1x1 = 9
const imageSize1x1_BORDERED = 8
const imageSize4x4 = 4.5
const imageSize4x4_BORDERED = 4
const imageSize4x4_horizontal = 3.5
const imageSize5x5_Square = 4.5
const imageSize5x5_Rect = { height: 3, width: 4.5 }
const imageSize5x5_Square_BORDERED = 4
const imageSize5x5_Rect_BORDERED = { height: 2.5, width: 4 }
const SINGLE_LINE_MULTIPLIER = 3
const DOUBLE_LINE_MULTIPLIER = 5.9
const MAX_SINGLE_LINE_CHARS = 45
const MAX_TITLE_CHARS = 60

interface IExportButtonProps {
  className: string
}
export const ExportButton = ({ className }: IExportButtonProps) => {
  const deck = useAppSelector((state) => state.decks)
  const isAdmin = useAppSelector(selectIsAdmin)
  const pptx = useMemo(() => new pptxgenjs(), [deck])

  useEffect(() => {
    pptx.author = 'GPS Promotions'
    pptx.company = 'GPS Promotions'
    pptx.revision = '1'
    pptx.subject = 'Continuity Deck'
    pptx.title = 'Sample Presentation'
  }, [pptx])

  // @DEV Checks for usage of alternate images, otherwise uses main product image
  function computePrimaryImage(cont: IContinuity, p: IProduct) {
    const altImageIdx = cont.altImages[p?.id]
    return altImageIdx >= 0 ? p?.imageAssets[altImageIdx] : p?.computedPrimaryImage
  }

  /* **************************************************
    create a 1X1 continuity slide
  ***************************************************** */
  function addSingleContinuityPage(cont: IContinuity) {
    const slide2 = pptx.addSlide({ masterName: 'CONTINUITY_SLIDE' })
    const computedImageUrl = computePrimaryImage(cont, cont.products[0])
    const product = cont.products[0]
    const url = product.transparentImage || resizeComputedImage(computedImageUrl, 1000, 1000)
    const productTitle =
      product.name.length > MAX_TITLE_CHARS
        ? product.name.substring(0, MAX_TITLE_CHARS)
        : product.name
    let titleLines = Math.ceil(productTitle.length / 17)
    titleLines = titleLines > 3 ? 3 : titleLines
    const titleHeight = titleLines * 5.6
    const layout = cont.layout

    // Background image if required
    if (layout === 'background' && cont.backgroundImage) {
      slide2.addImage({
        path: `${cont.backgroundImage}?crossorigin`,
        sizing: { type: 'contain', w: imageSize1x1, h: imageSize1x1 },
        x: '0%',
        y: '0%',
        w: imageSize1x1,
        h: imageSize1x1,
        transparency: interpolateOpacityToTransparency(cont.backgroundOpacity),
      })
    }

    // bordered shadow box if required
    if (layout !== 'borderless' && layout !== 'background') {
      slide2.addShape(pptx.ShapeType.rect, {
        x: 0.5,
        y: 0.5,
        w: imageSize1x1_BORDERED,
        h: imageSize1x1_BORDERED,
        fill: { color: 'FFFFFF' },
        shadow: { type: 'outer', opacity: 0.4, blur: 25, offset: 0, color: '222222' },
      })
    }

    slide2
      // Main image
      .addImage({
        path: `${url}?crossorigin`,
        sizing: {
          type: 'contain',
          w: layout === 'borderless' ? imageSize1x1 : imageSize1x1_BORDERED,
          h: layout === 'borderless' ? imageSize1x1 : imageSize1x1_BORDERED,
        },
        x: layout === 'borderless' ? '0%' : 0.5,
        y: layout === 'borderless' ? '0%' : 0.5,
        w: layout === 'borderless' ? imageSize1x1 : imageSize1x1_BORDERED,
        h: layout === 'borderless' ? imageSize1x1 : imageSize1x1_BORDERED,
      })

    // Brand image
    if (product.brandLogoImage) {
      slide2.addImage({
        path: resizeComputedImage(product.brandLogoImage || '', 200, 200),
        sizing: { type: 'contain', h: 1.5, w: 1.5 },
        x: '59%',
        y: '5%',
        h: 1.5,
        w: 1.5,
      })
    }

    // Product title
    slide2
      .addText(productTitle.toUpperCase(), {
        x: '58%',
        y: '23%',
        w: '38%',
        h: `${titleHeight}%`,
        valign: 'top',
        fontSize: 30,
        fontFace: AVENIR_NEXT_LT_PRO,
        bold: true,
        align: 'left',
        fit: 'resize',
      })
      .addText(totalContinuityPrice(cont, ContinuityType.single) || '', {
        x: '58%',
        y: `${26 + titleHeight}%`,
        w: '38%',
        h: '3%',
        fontSize: 24,
        fontFace: AVENIR_NEXT_LT_PRO,
        bold: true,
      })
      .addText('+ SHIPPING', {
        x: '58%',
        y: `${29 + titleHeight}%`,
        w: '38%',
        h: '3%',
        fontSize: 12,
        color: 'A3A3A3',
        fontFace: AVENIR_NEXT_LT_PRO,
      })
      .addText(product?.description ?? '', {
        x: '58%',
        y: `${33 + titleHeight}%`,
        w: '36%',
        h: '25%',
        valign: 'top',
        fit: 'resize',
        fontSize: 14,
        fontFace: AVENIR_NEXT_LT_PRO_LIGHT,
        align: 'left',
        color: 'A3A3A3',
      })

    if (isAdmin) {
      slide2.addNotes(`${format(new Date(), 'MM-dd-yyyy')}
Ref#: ${cont.id || 'none'}
Total cost: ${totalContinuityPrice(cont, constants.CONTINUITY_TYPES.SINGLE, true)}
-------------------------------------------------------------------
${product?.vendor}
${product?.name}\n
Product Item #: ${product?.manufacturerId}
Product Cost: ${formatter.format(product?.cost)}
Quantity: ${product?.inventory || 'Unknown'}
Lead Time: ${product?.leadTime || 'Unknown'}
${product.tier1 ? `Tier 1: ${product.tier1 || 0} at ${product.tier1Qty}` : ''}
${product.tier2 ? `Tier 2: ${product.tier2 || 0} at ${product.tier2Qty}` : ''}
${product.tier3 ? `Tier 3: ${product.tier3 || 0} at ${product.tier3Qty}` : ''}
${product.tier4 ? `Tier 4: ${product.tier4 || 0} at ${product.tier4Qty}` : ''}
    `)
    }
  }

  /* **************************************************
    create a 4X4 continuity slide
  ***************************************************** */
  async function addQuadContinuityPage(cont: IContinuity) {
    const slide2 = pptx.addSlide({ masterName: 'CONTINUITY_SLIDE' })

    const titleLines = cont.title ? Math.round(cont.title.length / 16) : 1
    const titleHeight = titleLines * 3.7
    const layout = cont.layout

    // Background image if required
    if (layout === 'background' && cont.backgroundImage) {
      slide2.addImage({
        path: `${cont.backgroundImage}?crossorigin`,
        sizing: { type: 'contain', w: imageSize1x1, h: imageSize1x1 },
        x: '0%',
        y: '0%',
        w: imageSize1x1,
        h: imageSize1x1,
        transparency: interpolateOpacityToTransparency(cont.backgroundOpacity),
      })
    }

    // replaces all products with a single image
    if (layout === 'overlay' && cont.overlayImage) {
      slide2.addImage({
        path: `${cont.overlayImage}?crossorigin`,
        sizing: { type: 'contain', w: imageSize1x1, h: imageSize1x1 },
        x: '0%',
        y: '0%',
        w: imageSize1x1,
        h: imageSize1x1,
      })
    }

    // bordered shadow box if required
    if (layout !== 'borderless' && layout !== 'overlay' && layout !== 'background') {
      slide2.addShape(pptx.ShapeType.rect, {
        x: 0.5,
        y: 0.5,
        w: imageSize1x1_BORDERED,
        h: imageSize1x1_BORDERED,
        fill: { color: 'FFFFFF' },
        shadow: { type: 'outer', opacity: 0.4, blur: 25, offset: 0, color: '222222' },
      })
    }

    // create and add images to grid if layout !== overlay
    const images = cont.products.map((p) => {
      if (p.transparentImage) {
        return p.transparentImage
      }
      const computedImageUrl = computePrimaryImage(cont, p)
      return resizeComputedImage(computedImageUrl, 600, 600)
    })
    slide2
      .addText((cont.title || '').toUpperCase(), {
        x: '58%',
        y: '23%',
        w: '40%',
        h: `${titleHeight}%`,
        valign: 'top',
        fontSize: 30,
        fontFace: AVENIR_NEXT_LT_PRO,
        bold: true,
        align: 'left',
        fit: 'resize',
      })
      .addText(totalContinuityPrice(cont, ContinuityType.quad) || '', {
        x: '58%',
        y: `${28 + titleHeight}%`,
        w: '40%',
        h: '3%',
        fontSize: 24,
        fontFace: AVENIR_NEXT_LT_PRO,
        bold: true,
      })
      .addText('+ SHIPPING PER WEEK', {
        x: '58%',
        y: `${31 + titleHeight}%`,
        w: '40%',
        h: '3%',
        fontSize: 12,
        color: 'A3A3A3',
        fontFace: AVENIR_NEXT_LT_PRO,
      })

    if (layout !== 'overlay') {
      slide2
        // top left
        .addImage({
          path: images[0],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
            h: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
          },
          x: layout === 'borderless' ? 0 : 0.5,
          y: layout === 'borderless' ? 0 : 0.5,
          w: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
          h: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
        })
        // top right
        .addImage({
          path: images[1],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
            h: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
          },
          x: imageSize4x4,
          y: layout === 'borderless' ? 0 : 0.5,
          w: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
          h: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
        })
        // bottom left
        .addImage({
          path: images[2],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
            h: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
          },
          x: layout === 'borderless' ? 0 : 0.5,
          y: imageSize4x4,
          w: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
          h: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
        })
        // bottom right
        .addImage({
          path: images[3],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
            h: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
          },
          x: imageSize4x4,
          y: imageSize4x4,
          w: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
          h: layout === 'borderless' ? imageSize4x4 : imageSize4x4_BORDERED,
        })
    }

    let y = 34 + titleHeight
    cont.products.map((p, idx) => {
      // @DEV if previous item length exceeds the single line max length,
      // .    then current item needs a larger y starting value (needs to wrap)
      // .    to start lower on the page
      const multiplier =
        cont.products[idx - 1]?.name?.length ?? 0 > MAX_SINGLE_LINE_CHARS
          ? DOUBLE_LINE_MULTIPLIER
          : SINGLE_LINE_MULTIPLIER
      y += multiplier
      slide2.addText(p.name?.slice(0, MAX_SINGLE_LINE_CHARS * 2), {
        x: '58%',
        y: `${y}%`,
        w: '34%',
        h: '10%',
        fontSize: 14,
        color: '#999999',
        fontFace: AVENIR_NEXT_LT_PRO,
        bullet: true,
        fit: 'resize',
        align: 'left',
        valign: 'top',
      })
    })

    if (isAdmin) {
      const notes = generatePowerpointNotes(cont)
      slide2.addNotes(notes)
    }
  }

  /* **************************************************
    create a 4X4 horizontal continuity slide
  ***************************************************** */
  async function addQuadHorizontalContinuityPage(cont: IContinuity) {
    const slide2 = pptx.addSlide({ masterName: 'CONTINUITY_SLIDE' })

    // create and add images to grid if layout !== overlay
    const images = cont.products.map((p) => {
      if (p.transparentImage) {
        return p.transparentImage
      }
      const computedImageUrl = computePrimaryImage(cont, p)
      return resizeComputedImage(computedImageUrl, 600, 600)
    })

    const w = imageSize4x4_horizontal
    const h = imageSize4x4_horizontal
    const text_height = 1.3
    const textOptions: Partial<pptxgenjs.TextPropsOptions> = {
      w: imageSize4x4_horizontal,
      h: text_height,
      fontSize: 20,
      fontFace: AVENIR_NEXT_LT_PRO,
      fit: 'resize',
      align: 'center',
      valign: 'top',
    }

    slide2
      // top left
      .addImage({
        path: images[0],
        sizing: {
          type: 'contain',
          w,
          h,
        },
        x: 0.5,
        y: 0.5,
      })
      .addText(cont.products[0].name || '', {
        x: 0.5,
        y: h + 0.5 + 0.1,
        ...textOptions,
      })
      // middle left
      .addImage({
        path: images[1],
        sizing: {
          type: 'contain',
          w,
          h,
        },
        x: w + 0.5,
        y: 0.5,
      })
      .addText(cont.products[1].name || '', {
        x: w + 0.5,
        y: h + 0.5 + 0.1,
        ...textOptions,
      })
      // middle right
      .addImage({
        path: images[2],
        sizing: {
          type: 'contain',
          w,
          h,
        },
        x: w * 2 + 1,
        y: 0.5,
      })
      .addText(cont.products[2].name || '', {
        x: w * 2 + 1,
        y: h + 0.5 + 0.1,
        ...textOptions,
      })
      // top right
      .addImage({
        path: images[3],
        sizing: {
          type: 'contain',
          w,
          h,
        },
        x: w * 3 + 1.5,
        y: 0.5,
      })
      .addText(cont.products[3].name || '', {
        x: w * 3 + 1.5,
        y: h + 0.5 + 0.1,
        ...textOptions,
      })
      // Bottom text section
      .addText((cont.title || 'PROGRAM NAME').toUpperCase().slice(0, 80), {
        x: 0.5,
        y: h + text_height + 1,
        w: '60%',
        h: 2.1,
        valign: 'top',
        fontSize: 40,
        fontFace: AVENIR_NEXT_LT_PRO,
        bold: true,
        align: 'left',
        fit: 'resize',
      })
      .addText(totalContinuityPrice(cont, ContinuityType.quad) || '', {
        x: 0.5,
        y: '82%',
        w: '40%',
        h: 1,
        fontSize: 28,
        fontFace: AVENIR_NEXT_LT_PRO,
        bold: true,
      })
      .addText('+ SHIPPING PER WEEK', {
        x: 0.5,
        y: '90%',
        w: '40%',
        h: '3%',
        fontSize: 12,
        color: 'A3A3A3',
        fontFace: AVENIR_NEXT_LT_PRO,
      })

    if (isAdmin) {
      const notes = generatePowerpointNotes(cont)
      slide2.addNotes(notes)
    }
  }

  /* **************************************************
    create a 5X5 continuity slide
  ***************************************************** */
  async function addQuintContinuityPage(cont: IContinuity) {
    const slide2 = pptx.addSlide({ masterName: 'CONTINUITY_SLIDE' })

    const titleLines = cont.title ? Math.round(cont.title.length / 16) : 1
    const titleHeight = titleLines * 3.7
    const layout = cont.layout

    // Background image if required
    if (layout === 'background' && cont.backgroundImage) {
      slide2.addImage({
        path: `${cont.backgroundImage}?crossorigin`,
        sizing: { type: 'contain', w: imageSize1x1, h: imageSize1x1 },
        x: '0%',
        y: '0%',
        w: imageSize1x1,
        h: imageSize1x1,
        transparency: interpolateOpacityToTransparency(cont.backgroundOpacity),
      })
    }

    // replaces all products with a single image
    if (layout === 'overlay' && cont.overlayImage) {
      slide2.addImage({
        path: `${cont.overlayImage}?crossorigin`,
        sizing: { type: 'contain', w: imageSize1x1, h: imageSize1x1 },
        x: '0%',
        y: '0%',
        w: imageSize1x1,
        h: imageSize1x1,
      })
    }

    // bordered shadow box if required
    if (layout !== 'borderless' && layout !== 'overlay' && layout !== 'background') {
      slide2.addShape(pptx.ShapeType.rect, {
        x: 0.5,
        y: 0.5,
        w: imageSize1x1_BORDERED,
        h: imageSize1x1_BORDERED,
        fill: { color: 'FFFFFF' },
        shadow: { type: 'outer', opacity: 0.4, blur: 25, offset: 0, color: '222222' },
      })
    }

    // create and add images to grid if layout !== overlay
    const images = cont.products.map((p, idx) => {
      const computedImageUrl = computePrimaryImage(cont, p)
      const imageUrl = p.transparentImage
      if (idx === 0 || idx === 3) {
        return imageUrl ?? resizeComputedImage(computedImageUrl, 540, 540)
      } else {
        return imageUrl ?? resizeComputedImage(computedImageUrl, 292, 440)
      }
    })
    slide2
      .addText((cont.title || '').toUpperCase(), {
        x: '58%',
        y: '23%',
        w: '40%',
        h: `${titleHeight}%`,
        valign: 'top',
        fontSize: 30,
        fontFace: AVENIR_NEXT_LT_PRO,
        bold: true,
        align: 'left',
        fit: 'resize',
      })
      .addText(totalContinuityPrice(cont, ContinuityType.quint) || '', {
        x: '58%',
        y: `${28 + titleHeight}%`,
        w: '40%',
        h: '3%',
        fontSize: 24,
        fontFace: AVENIR_NEXT_LT_PRO,
        bold: true,
      })
      .addText('+ SHIPPING PER WEEK', {
        x: '58%',
        y: `${31 + titleHeight}%`,
        w: '40%',
        h: '3%',
        fontSize: 12,
        color: 'A3A3A3',
        fontFace: AVENIR_NEXT_LT_PRO,
      })

    if (layout !== 'overlay') {
      // top left
      slide2
        .addImage({
          path: images[0],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize5x5_Square : imageSize5x5_Square_BORDERED,
            h: layout === 'borderless' ? imageSize5x5_Square : imageSize5x5_Square_BORDERED,
          },
          x: layout === 'borderless' ? 0 : 0.5,
          y: layout === 'borderless' ? 0 : 0.5,
          w: layout === 'borderless' ? imageSize5x5_Square : imageSize5x5_Square_BORDERED,
          h: layout === 'borderless' ? imageSize5x5_Square : imageSize5x5_Square_BORDERED,
        })
        // top right
        .addImage({
          path: images[1],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize5x5_Rect.width : imageSize5x5_Rect_BORDERED.width,
            h:
              layout === 'borderless'
                ? imageSize5x5_Rect.height
                : imageSize5x5_Rect_BORDERED.height,
          },
          x: imageSize5x5_Square,
          y: layout === 'borderless' ? 0 : 0.5,
          w: layout === 'borderless' ? imageSize5x5_Rect.width : imageSize5x5_Rect_BORDERED.width,
          h: layout === 'borderless' ? imageSize5x5_Rect.height : imageSize5x5_Rect_BORDERED.height,
        })
        // middle right
        .addImage({
          path: images[2],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize5x5_Rect.width : imageSize5x5_Rect_BORDERED.width,
            h:
              layout === 'borderless'
                ? imageSize5x5_Rect.height
                : imageSize5x5_Rect_BORDERED.height,
          },
          x: imageSize5x5_Square,
          y: imageSize5x5_Rect.height,
          w: layout === 'borderless' ? imageSize5x5_Rect.width : imageSize5x5_Rect_BORDERED.width,
          h: layout === 'borderless' ? imageSize5x5_Rect.height : imageSize5x5_Rect_BORDERED.height,
        })
        // bottom left
        .addImage({
          path: images[3],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize5x5_Square : imageSize5x5_Square_BORDERED,
            h: layout === 'borderless' ? imageSize5x5_Square : imageSize5x5_Square_BORDERED,
          },
          x: layout === 'borderless' ? 0 : 0.5,
          y: imageSize5x5_Square,
          w: layout === 'borderless' ? imageSize5x5_Square : imageSize5x5_Square_BORDERED,
          h: layout === 'borderless' ? imageSize5x5_Square : imageSize5x5_Square_BORDERED,
        })
        // bottom right
        .addImage({
          path: images[4],
          sizing: {
            type: 'contain',
            w: layout === 'borderless' ? imageSize5x5_Rect.width : imageSize5x5_Rect_BORDERED.width,
            h:
              layout === 'borderless'
                ? imageSize5x5_Rect.height
                : imageSize5x5_Rect_BORDERED.height,
          },
          x: imageSize5x5_Square,
          y: imageSize5x5_Rect.height * 2,
          w: layout === 'borderless' ? imageSize5x5_Rect.width : imageSize5x5_Rect_BORDERED.width,
          h: layout === 'borderless' ? imageSize5x5_Rect.height : imageSize5x5_Rect_BORDERED.height,
        })
    }

    let y = 34 + titleHeight
    cont.products.map((p, idx) => {
      // @DEV if previous item length exceeds the single line max length,
      // .    then current item needs a larger y starting value (needs to wrap)
      // .    to start lower on the page
      const multiplier =
        cont.products[idx - 1]?.name.length > MAX_SINGLE_LINE_CHARS
          ? DOUBLE_LINE_MULTIPLIER
          : SINGLE_LINE_MULTIPLIER
      y += multiplier
      slide2.addText(p.name?.slice(0, MAX_SINGLE_LINE_CHARS * 2), {
        x: '58%',
        y: `${y}%`,
        w: '34%',
        h: '10%',
        fontSize: 14,
        color: '#999999',
        fontFace: AVENIR_NEXT_LT_PRO,
        bullet: true,
        fit: 'resize',
        align: 'left',
        valign: 'top',
      })
    })

    if (isAdmin) {
      const notes = generatePowerpointNotes(cont)
      slide2.addNotes(notes)
    }
  }

  async function onExport() {
    pptx.defineLayout({ name: '16x9', width: 16, height: 9 })
    pptx.layout = '16x9'

    pptx.defineSlideMaster({
      title: 'TITLE_SLIDE',
      background: {
        path: titleSlideBlackBackground,
      },
      // background: {
      //   path: deck.recipient ? titleSlideBackground : titleSlideBlankBackground,
      // },
      objects: [
        {
          text: {
            text: 'EXCLUSIVELY MADE FOR',
            options: {
              x: 9,
              y: 3.75,
              w: 7,
              color: 'FFFFFF',
              fontFace: AVENIR_NEXT_LT_PRO,
              fontSize: 32,
              align: 'center',
              bold: true,
            },
          },
        },
        {
          placeholder: {
            text: '<custom name>',
            options: {
              name: 'name',
              type: 'title',
              x: 9.25,
              y: 4,
              w: 6.5,
              h: 2.5,
              // color: '4B2979',
              color: 'FFFFFF',
              fontSize: 24,
              fontFace: AVENIR_NEXT_LT_PRO_LIGHT,
              align: 'center',
            },
          },
        },
      ],
    })

    // Slide master for a single product continuity
    pptx.defineSlideMaster({
      title: 'CONTINUITY_SLIDE',
      background: { color: 'FFFFFF' },
      objects: [
        {
          image: {
            path: 'https://gps-portal-background-images75804-dev.s3.us-west-2.amazonaws.com/public/gps_logo.png',
            sizing: { type: 'contain', w: 1.1, h: 0.5 },
            x: '90%',
            y: '90%',
            w: 1.1,
            h: 0.5,
          },
        },
      ],
    })

    /* ************************
      Create slides for each continuity 
    ************************* */
    deck.continuities.forEach((c) => {
      if (c.type === ContinuityType.single) {
        addSingleContinuityPage(c)
      } else if (c.type === ContinuityType.quad) {
        addQuadContinuityPage(c)
      } else if (c.type === ContinuityType.quad_horizontal) {
        addQuadHorizontalContinuityPage(c)
      } else if (c.type === ContinuityType.quint) {
        addQuintContinuityPage(c)
      } else if (c.type === ContinuityType.title) {
        /* ************************
          Create title slide 
        ************************* */
        const slide1 = pptx.addSlide({ masterName: 'TITLE_SLIDE' })
        if (deck.recipient) {
          slide1.addText(deck.recipient.toUpperCase(), { placeholder: 'name' })
        }
      } else {
        throw new Error('Invalid continuity type')
      }
    })

    pptx.writeFile({ fileName: 'continuity.pptx' })
  }

  return (
    <Button variant="primary" size="s" onClick={onExport} className={className} shadow="none">
      EXPORT TO PPT
    </Button>
  )
}
