import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { BackButton } from '../components/buttons/BackButton'
import { ProductDetailView } from '../components/continuityBuilder/ProductDetailView'
import { selectOrganizationMarkup, selectUser } from '../data/user/selectors'

export const ProductDetail = () => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()

  const orgMarkup = useSelector(selectOrganizationMarkup)
  const user = useSelector(selectUser)

  const { slot, page, currentList, sortState, from, fromNotes, fromList } = location.state || {}

  function onBrandClick(product) {
    history.push({
      pathname: from ?? '/products',
      state: { slot, filter: `brand:${product.brand}`, from: 'product_detail' },
    })
  }

  function onVendorClick(product) {
    history.push({
      pathname: from ?? '/products',
      state: { slot, filter: `vendor:${product.vendor}`, from: 'product_detail' },
    })
  }

  function onTagClick(tag) {
    history.push({
      pathname: from ?? '/products',
      state: { filter: `tags:${tag}`, from: 'product_detail' },
    })
  }

  function onSubcategoryClick(subcategory, type) {
    history.push({
      pathname: from ?? '/products',
      state: { filter: `${type}:${subcategory}`, from: 'product_detail' },
    })
  }

  function handleBackNavigation() {
    if (currentList && slot == undefined) {
      // when we go pack, we need to restore the previous state of the list sort
      history.push({
        pathname: `/profile/${user?.id}/list/${currentList.id}`,
        state: { id, page, sortState },
      })
    } else {
      history.push({
        pathname: from ?? '/products',
        state: { id, page, sortState, fromList },
      })
    }
  }

  return (
    <div className="scrollbar flex h-full w-full flex-col overflow-y-auto px-8 sm:px-16">
      <div className="flex items-center py-3">
        <BackButton text="Go back" onClick={handleBackNavigation} />
      </div>
      <ProductDetailView
        id={id}
        slot={slot}
        onAddSlideComplete={handleBackNavigation}
        onBrandClick={onBrandClick}
        onVendorClick={onVendorClick}
        onTagClick={onTagClick}
        onSubcategoryClick={onSubcategoryClick}
        orgMarkup={orgMarkup}
        fromNotes={fromNotes}
      />
    </div>
  )
}
