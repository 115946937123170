import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { addExistingSlide, updateCurrentDeck } from 'data/decks'
import { api } from 'services/api'

import { LoadingSpinner } from 'components/LoadingSpinner'
import { MasterSlideCard } from 'components/MasterSlideCard'
import { SearchInput } from 'components/inputs/SearchInput'
import { selectFilterString } from 'data/globals/selectors'
import { ReactComponent as EmptyState } from '../assets/svgs/empty_state.svg'

export function MasterContinuitiesTab() {
  const alert = useAlert()
  const history = useHistory()

  const currentDirectory = useSelector((state) => state.globals.currentDirectory)
  const filterString = useSelector(selectFilterString)

  const [searchTerm, setSearchTerm] = useState('')

  async function handleItemClick(item) {
    try {
      addExistingSlide(item)
      updateCurrentDeck({
        id: item.master.id,
        filename: item.master.filename,
        directoryId: item.master.directoryId,
        type: item.master.type,
        filename: item.master.filename,
      })
      history.replace('/builder')
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  const fetchMyContinuities = async () => {
    try {
      const { data } = await api.Directory.searchMasters({
        search: searchTerm.trim(),
        // offset: page * PAGE_SIZE,
        // limit: PAGE_SIZE,
        filter: filterString.length > 0 ? filterString.join('_') : '',
        order_by: 'updatedAt',
        order: 'DESC',
        // exclusives_only: exclusivesOnly,
        // org_markup: orgMarkup,
        org_id: '',
      })
      return data
    } catch (error) {
      alert.error(error.message)
    }
  }

  const { isLoading, data, error, refetch } = useQuery(
    ['master-continuities', searchTerm],
    () => fetchMyContinuities(),
    {
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (error) {
      alert.error(error.message)
    }
  }, [error])

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  const handleSlideDelete = async (slideId) => {
    refetch()
  }

  return (
    <>
      {currentDirectory ? (
        <div className="flex w-full flex-col">
          <div
            className="sticky top-0 z-10 flex items-center justify-between bg-white py-2"
            style={{ minHeight: '54px' }}
          >
            <SearchInput
              className="h-9 w-1/2"
              onChange={handleSearch}
              placeholder="Search by product or slide"
            />
          </div>
          {searchTerm && data.length === 0 ? (
            <div className="mt-6 w-full">
              <p className="text-center font-semibold">
                Sorry, this person doesn&apos;t have any saved continuities to show.
              </p>
              <EmptyState className="mx-auto h-64" />
            </div>
          ) : (
            <ul
              role="list"
              className="grid grid-cols-1 gap-x-4 gap-y-8 px-4 pt-2 sm:grid-cols-3 sm:gap-x-4 md:grid-cols-4 md:gap-x-6 xl:grid-cols-6 xl:gap-x-8"
            >
              {data?.map((s, idx) => {
                return (
                  <MasterSlideCard
                    key={idx}
                    builder={true}
                    continuity={s}
                    onAddSlide={() => handleItemClick(s)}
                    onDelete={handleSlideDelete}
                  />
                )
              })}
            </ul>
          )}
        </div>
      ) : (
        <div className="mt-6 w-full">
          <p className="text-center font-semibold">
            Sorry, there are no results matching this search.
          </p>
          <EmptyState className="mx-auto h-64" />
        </div>
      )}
      {isLoading && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
      {/* <GenericModal
        show={showWarning}
        title="Warning"
        onClose={() => {
          setShowWarning(false)
          setFolderForDeletion(null)
        }}
        onAccept={handleDeleteFolder}
      >
        <div>
          Are you sure you want to delete {folderForDeletion?.name}? This will remove all it&apos;s
          contents and cannot be undone.
        </div>
      </GenericModal> */}
    </>
  )
}
