import { SlideLayoutType } from 'types/modelTypes'

export const constants = Object.freeze({
  SALSIFY_API_KEY: 'GZHNAdLJbBlHuA2TwmuZt4mNeRVh3jpRHadfQW4aU_w',
  SALSIFY_ORGANIZATION_ID: 's-119d1bcc-79e3-48f7-9cd6-7d9d12e08fe8',
  SALSIFY_BASE_API_URL: `https://app.salsify.com/api/v1/orgs/s-119d1bcc-79e3-48f7-9cd6-7d9d12e08fe8`,
  STORED_TOKEN: 'GPS_SESSION_TOKEN',
  TOKEN_EXPIRY: 'GPS_SESSION_EXPIRY',
  BASE_API_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_BASE_API_URL
      : 'http://localhost:3030',
  ROLES: {
    ADMIN: 'admin',
    USER: 'user',
  },
  CONTINUITY_TYPES: {
    SINGLE: 'single',
    QUAD: 'quad',
    QUINT: 'quint',
    TITLE: 'title',
  },
  USER_STATUS: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    INVITED: 'invited',
  },
  SLIDE_TYPE_OPTIONS: [
    { id: 0, name: '1 Item', value: 'single' },
    { id: 1, name: '4 Item', value: 'quad' },
    { id: 1, name: '4 Item Horizontal', value: 'quad_horizontal' },
    { id: 2, name: '5 Item', value: 'quint' },
  ],
  SLIDE_LAYOUT_OPTIONS: [
    { id: 0, name: 'Borderless', value: SlideLayoutType.BORDERLESS },
    { id: 1, name: 'Bordered', value: SlideLayoutType.BORDERED },
    { id: 2, name: 'Background', value: SlideLayoutType.BACKGROUND },
    { id: 3, name: 'Overlay', value: SlideLayoutType.OVERLAY },
  ],
  PRODUCT_SORT_OPTIONS: {
    admin: [
      { id: 1, name: 'Recently added', value: 'salsifyUpdatedAt', desc: true },
      { id: 2, name: 'Cost - Low-High', value: 'cost', desc: false },
      { id: 3, name: 'Cost - High-Low', value: 'cost', desc: true },
      { id: 4, name: 'Inventory - High-Low', value: 'inventory', desc: true },
      { id: 5, name: 'Inventory - Low-High', value: 'inventory', desc: false },
    ],
    user: [
      { id: 1, name: 'Recently added', value: 'salsifyUpdatedAt', desc: true },
      { id: 2, name: 'Price - Low-High', value: 'cost', desc: false },
      { id: 3, name: 'Price - High-Low', value: 'cost', desc: true },
      { id: 4, name: 'Inventory - High-Low', value: 'inventory', desc: true },
      { id: 5, name: 'Inventory - Low-High', value: 'inventory', desc: false },
    ],
  },
  ORG_TIERS: {
    tier1: 25,
    tier2: 35,
    tier3: 100,
    tier4: 500,
  },
})

export default constants
