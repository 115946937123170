import { useAppSelector } from 'data/reduxHooks'
import { IProduct } from 'types/modelTypes'
import { formatter } from 'utils/helpers'

interface IExtendedProduct extends Omit<IProduct, 'price'> {
  price: string
}

// type RequiredKeyTranslations = Partial<{
//   [key in keyof IProduct]: string
// }>
type RequiredKeyTranslations = Record<string, string>

const requiredKeyTranslations: RequiredKeyTranslations = {
  handle: 'Handle',
  name: 'Title',
  description: 'Body (HTML)',
  brand: 'Vendor',
  // vendor: 'Vendor Name',
  category: 'Product Category',
  subCategory: 'Type',
  tags: 'Tags',
  published: 'Published',
  option1name: 'Option1 Name',
  option1value: 'Option1 Value',
  option2name: 'Option2 Name',
  option2value: 'Option2 Value',
  option3name: 'Option3 Name',
  option3value: 'Option3 Value',
  // colors: 'Color',
  manufacturerId: 'Variant SKU',
  variantGrams: 'Variant Grams',
  variantInventoryTracker: 'Variant Inventory Tracker',
  inventory: 'Variant Inventory QTY',
  variantInventoryPolicy: 'Variant Inventory Policy',
  variantFulfillmentService: 'Variant Fulfillment Service',
  price: 'Variant Price',
  msrp: 'Variant Compare At Price',
  variantRequiresShipping: 'Variant Requires Shipping',
  variantTaxable: 'Variant Taxable',
  variantBarcode: 'Variant Barcode',
  primaryImage: 'Image Src 1',
  primaryImagePosition: 'Image Position 1',
  lifestyleImage: 'Image Src 2',
  lifestyleImagePosition: 'Image Position 2',
  packagingImage: 'Image Src 3',
  packagingImagePosition: 'Image Position 3',
  brandLogoImage: 'Image Src 4',
  brandLogoImagePosition: 'Image Position 4',
  transparentImage: 'Image Src 5',
  transparentImagePosition: 'Image Position 5',
  imageAltText: 'Image Alt Text',
  giftCard: 'Gift Card',
  variantImage: 'Variant Image',
  cost: 'Cost per item',
  includedUnitedStates: 'Included / United States',
  priceUnitedStates: 'Price / United States',
  includedInternational: 'Included / International',
  priceInternational: 'Price / International',
  compareAtPrice: 'Compare At Price / International',
  status: 'Status',
  metafieldActiveInactive: 'Metafield:custom.active_inactive',
  variantMetafieldSellPrice: 'Variant Metafield:custom.sell_price',
  variantMetafieldShippingPrice: 'Variant Metafield:custom.shipping_price',
  metafieldVendorName: 'Metafield:custom.vendor_name',
  vendorIdPV2: 'Metafield:custom.vendor_id_pv2',
  metafieldProstoreId: 'Metafield:custom.prostore_id',
  specSheet: 'Metafield:custom.spec_sheet_general_or_apparel_',
  metafieldShippingCompanyPV2: 'Metafield:custom.shipping_company_pv2_',
  metafieldShippingCarrier: 'Metafield:custom.shipping_carrier',
  command: 'Command',
  // freightCost: 'Shipping Cost',
  // vendorIdPV2: 'Vendor ID (PV2)',
  // specSheet: 'SPEC SHEET',
  computedPrimaryImage: 'Image Src 0',
}

export default function useCSVExport() {
  const deck = useAppSelector((state) => state.decks)

  function exportJSONToCSV(objArray: RequiredKeyTranslations[]) {
    var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
    var headerStr =
      `${Object.keys(arr[0])
        .map((value) => {
          // We want to export all image urls with the same header name
          if (value.includes('Image Src')) {
            return `"Image Src"`
          }
          if (value.includes('Image Position')) {
            return `"Image Position"`
          }
          return `"${value}"`
        })
        .join(',')}` + '\r\n'
    var csvContent = arr.reduce(
      (st: string, next: { [s: string]: unknown } | ArrayLike<unknown>) => {
        st +=
          `${Object.values(next)
            .map(
              (value) =>
                `"${`${value ? value : ''}`
                  .replace(/"/g, '\u201d') // escape any double quotes in string
                  .replace(/#/g, '') // problematic character
                  .replace(/\*/g, '') // problematic character
                  .replace(/[\n\r]+/g, '')}"`, // escape any new lines in string
            )
            .join(',')}` + '\r\n'
        console.log('ST', st)
        return st
      },
      headerStr,
    )

    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `csv_export.csv`
    a.click()
    window.URL.revokeObjectURL(url)
  }

  function translate(p: IExtendedProduct) {
    const translated: { [key: string]: IExtendedProduct[keyof IExtendedProduct] } = {}
    for (const [key, translatedKey] of Object.entries(requiredKeyTranslations)) {
      if (translatedKey && key in p) {
        translated[translatedKey] = p[key as keyof IExtendedProduct]
      } else {
        translated[translatedKey] = ''
      }
    }
    return translated
  }

  const exportCSV = () => {
    const translatedProducts = deck.continuities
      // Calculate product prices
      .flatMap(
        (continuity) =>
          continuity.products?.map((product) => ({
            ...product,
            price: formatter.format(Number(product.cost) / (Number(continuity.priceModifier) ?? 1)),
          })) ?? [],
      )
      // Filter out title slides that do not have products
      .filter((product: IExtendedProduct) => product.hasOwnProperty('productId'))
      // Translate product keys
      .map(translate)

    exportJSONToCSV(translatedProducts as RequiredKeyTranslations[])
  }

  return { exportCSV }
}
