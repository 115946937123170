/* eslint-disable react/display-name */
import { Tab } from '@headlessui/react'
import { ReactElement } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { MasterContinuitiesTab } from 'components/MasterContinuitiesTab'
import { MyContinuitiesTab } from 'components/MyContinuitiesTab'
import { MyListsTab } from 'components/MyListsTab'

import { IUser } from 'types/modelTypes'
import { classNames } from 'utils/helpers'

interface IProfileTabsProps {
  user: IUser
  onTabChange: (index: number) => void
  history: any
  forceFetch: boolean
}
export default function PofileTabs({ user, onTabChange, history, forceFetch }: IProfileTabsProps) {
  const { url } = useRouteMatch()

  const categories: Record<string, Record<string, () => ReactElement>> = {
    'My Lists': {
      render: () => <MyListsTab forceFetch={forceFetch} user={user} />,
    },
    'My Continuities': {
      render: () => <MyContinuitiesTab user={user} />,
    },
  }

  if (user.masterAccess) {
    categories['Master Slides'] = {
      render: () => <MasterContinuitiesTab />,
    }
  }

  function handleTabChange(index: number) {
    onTabChange(index)
    history.replace(url)
  }

  return (
    <div className="h-full w-full px-2 pb-2 sm:px-0">
      <Tab.Group onChange={handleTabChange}>
        <Tab.List className="flex space-x-1 rounded-xl p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-purple-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 py-2 text-left text-sm font-medium outline-none ring-0 focus:outline-none',
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="h-full">
          {Object.values(categories).map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames('bg-white', 'h-full focus:outline-none focus:ring-0')}
            >
              {tab.render()}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
