export const migrations = {
  0: (state) => {
    return {
      ...state,
      continuities: {
        ...state.continuities,
        crumbs: [
          {
            name: 'Users',
            level: 'users',
            directoryId: null,
            userId: null,
            current: true,
          },
        ],
      },
    }
  },
  1: (state) => {
    return {
      ...state,
      globals: {
        ...state.globals,
        priceAdjustOptions: [
          { id: 0, name: 'None', value: 1 },
          { id: 1, name: '20%', value: 0.8 },
          { id: 2, name: '25%', value: 0.75 },
          { id: 3, name: '30%', value: 0.7 },
          { id: 4, name: '35%', value: 0.65 },
          { id: 5, name: 'Custom', value: 'custom' },
        ],
      },
    }
  },
  2: (state) => {
    return {
      ...state,
      globals: {
        ...state.globals,
        currentList: null,
        crumbs: [
          {
            name: 'Users',
            level: 'users',
            directoryId: null,
            userId: null,
            current: true,
          },
        ],
      },
    }
  },
  3: (state) => {
    return {
      ...state,
      globals: {
        ...state.globals,
        currentProductSort: {
          id: 1,
          name: 'Recently added',
          value: 'salsifyUpdatedAt',
          desc: true,
        },
      },
    }
  },
  4: (state) => {
    return {
      ...state,
      globals: {
        ...state.globals,
        filters: [],
      },
    }
  },
  5: (state) => {
    return {
      ...state,
      selections: {
        ...state.selections,
        selectedSlides: {},
      },
    }
  },
  6: (state) => {
    return {
      ...state,
      globals: {
        ...state.globals,
        priceAdjustOptions: [
          { id: 0, name: 'None', value: 1 },
          { id: 1, name: '20%', value: 0.8 },
          { id: 2, name: '25%', value: 0.75 },
          { id: 3, name: '30%', value: 0.7 },
          { id: 4, name: '35%', value: 0.65 },
          { id: 5, name: 'Custom', value: -1 },
        ],
      },
    }
  },
}
