import { FilterIcon } from '@heroicons/react/outline'

export const FilterButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      className="ml-6 flex h-8 w-8 items-center justify-center rounded border border-gray-300 shadow hover:shadow-none"
    >
      <FilterIcon className="h-6 w-6 text-gray-500" />
    </button>
  )
}
