import axios from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import ReactPaginate from 'react-paginate'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import { v4 as uuid } from 'uuid'

import { LoadingSpinner } from 'components/LoadingSpinner'
import { MultiSelectBar } from 'components/MultiSelectBar'
import { BackButton } from 'components/buttons/BackButton'
import { ProductDetailView } from 'components/continuityBuilder/ProductDetailView'
import AdvancedFilters from 'components/filters/AdvancedFilters'
import ClientFilters from 'components/filters/ClientFilters'
import { createFilterFromAttribute } from 'components/filters/utils'
import { SearchInput } from 'components/inputs/SearchInput'
import ContinuitiesCardGrid from 'components/productsView/ContinuitiesCardGrid'
import ProductCardGrid from 'components/productsView/ProductCardGrid'
import ResultsAndSortSection from 'components/productsView/ResutlsAndSortSection'
import { Button } from 'components/small/Button'
import { FilterButton } from 'components/small/FilterButton'
import { selectCurrentSlide } from 'data/decks/selectors'
import { updateFavorites } from 'data/favorites'
import { setCurrentProductSort, setFilters } from 'data/globals'
import { selectFilterString, selectProductSortOptions } from 'data/globals/selectors'
import { AndOrType, IAdminFilter, ISortOption } from 'data/globals/types'
import { useAppSelector } from 'data/reduxHooks'
import { updateSelectedProducts } from 'data/selections'
import { selectedProducts } from 'data/selections/selectors'
import {
  selectExclusivesOnly,
  selectIsAdmin,
  selectOrganizationMarkup,
  selectUser,
  selectUserRole,
} from 'data/user/selectors'
import { api } from 'services/api'
import { RequestMeta } from 'services/requests'
import { IProduct } from 'types/modelTypes'
import constants from 'utils/constants'
import { useScrollPosition } from 'utils/hooks'

import { ReactComponent as EmptyState } from '../assets/svgs/empty_state.svg'

const PAGE_SIZE = 100

type SelectedPageType = {
  selected: number
}

type DetailsViewType = {
  slot: number
  id: number
}

type LocationStateType = {
  sortState: ISortOption
  showContinuities: boolean
  filter: string
  page: number
  id: number
  from: string
}

export type ProductSelectionType = { id: number; add: boolean; product: IProduct }

interface IProductsViewProps {
  slot?: number | null
  onAddComplete?: () => void
  useCache?: boolean
  onProductsChange?: (products: IProduct[]) => void
  builder?: boolean
  fromNotes?: boolean
}
export default function ProductsView({
  slot = null,
  onAddComplete,
  useCache,
  onProductsChange,
  builder = false,
  fromNotes = false,
}: IProductsViewProps) {
  const alert = useAlert()
  const location = useLocation<LocationStateType>()
  const history = useHistory()

  const isAdmin = useAppSelector(selectIsAdmin)
  const exclusivesOnly = useAppSelector(selectExclusivesOnly)
  const user = useAppSelector(selectUser)
  const userRole = useAppSelector(selectUserRole)
  const favorites = useAppSelector((state) => state.favorites)
  const filterString = useAppSelector(selectFilterString)
  const filters = useAppSelector((state) => state.globals.filters)
  const selected = useAppSelector(selectedProducts)
  const { currentSlide } = useAppSelector(selectCurrentSlide)
  const orgMarkup = useAppSelector(selectOrganizationMarkup)
  const sort = useAppSelector((state) => state.globals.currentProductSort)
  const sortOptions = useAppSelector(selectProductSortOptions)
  // const orgIsAllAccess = useSelector(selectOrganizationIsAllAccess)

  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1) // page is 0 indexed
  const [showDetailsViewFor, setShowDetailsViewFor] = useState<DetailsViewType | null>(null) // { slot: number, id: number }
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const [isSlidesVisible, setIsSlidesVisible] = useState(false)

  const scrollRef = useRef<HTMLUListElement | null>(null)
  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
    null,
    false,
    300,
  )

  useEffect(() => {
    if (filters.length > 0 && userRole === constants.ROLES.ADMIN) {
      setShowAdvancedFilters(true)
    }
  }, [filters])

  // DEV if brand/vendor/tag is clicked on product details screen, redirect with that info
  useEffect(() => {
    if (location?.state?.sortState) {
      setCurrentProductSort(location.state.sortState)
    }
    if (location?.state?.showContinuities) {
      setIsSlidesVisible(true)
    }
    if (location?.state?.filter) {
      const [prop, attr] = location.state.filter.split(':')
      const newFilter = {
        key: uuid(),
        complete: true,
        prop: {
          label: `${prop[0].toUpperCase()}${prop.slice(1)}`,
          value: prop,
          type: 'string',
        },
        attr: [
          {
            name: attr,
            label: attr,
            value: attr,
          },
        ],
        operator: {
          label: 'Equals',
          value: 'or',
          type: ['string', 'bool', 'int'],
          filterType: 'and',
        },
        andOr: AndOrType.or,
      }
      setFilters([newFilter])
    }
  }, [location])

  useEffect(() => {
    if (location?.state?.page >= 0) {
      setPage(location.state.page)
      // scroll to previous item if available
      // but wait until setPage is called
      setTimeout(() => {
        const item = document.querySelector(`#restore-${location?.state?.id}`)
        if (item) {
          item.scrollIntoView()
        }
      }, 10)
    } else {
      // scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [history, location])

  const getFavorites = useCallback(async () => {
    try {
      if (user) {
        const { data } = await api.User.getFavorites(user.id)
        updateFavorites(data.map((d) => d.id))
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message || 'Could not load favorites')
      }
    }
  }, [user, alert])

  const fetchProducts = async () => {
    const res = await api.Products.getAll({
      search: searchTerm.trim(),
      offset: page * PAGE_SIZE,
      limit: PAGE_SIZE,
      filter: filterString.length > 0 ? filterString.join('_') : '',
      order_by: sort.value,
      order: sort.desc ? 'DESC' : 'ASC',
      exclusives_only: exclusivesOnly,
      org_markup: orgMarkup,
      // @ts-ignore
      org_id: isAdmin ? '' : user?.organizationId, // sending the org id will force only products related to the org
      // org_id: isAdmin || orgIsAllAccess ? '' : user?.organizationId, // sending the org id will force only products related to the org
    })
    if (res.meta?.total_results) {
      setTotalPages(Math.ceil(res.meta.total_results / PAGE_SIZE))
    }

    return {
      data: res.data,
      meta: res.meta,
    }
  }

  useEffect(() => {
    getFavorites()
  }, [getFavorites])

  const { isLoading, error, data, isFetching } = useQuery<
    | {
        data: IProduct[]
        meta: RequestMeta | undefined
      }
    | undefined,
    Error
  >(['projects', searchTerm, filterString, sort, page, exclusivesOnly], () => fetchProducts(), {
    keepPreviousData: true,
  })

  useEffect(() => {
    if (error) {
      alert.error(error.message)
    }
  }, [error, alert])

  useEffect(() => {
    if (data?.data?.length && onProductsChange) {
      onProductsChange(data.data)
    }
  }, [data?.data])

  // scroll restoration
  useEffect(() => {
    const item = document.querySelector(`#restore-${location?.state?.id}`)
    if (item) {
      item.scrollIntoView()
    }
  }, [])

  const handleSearch = (term: string) => {
    setSearchTerm(term)
    if (page !== 0) {
      setPage(0)
    }
  }

  // function handleSearchSubmit(term) {
  //   setSearchTermToFilter(term)
  // }

  function handleSelect(data: ProductSelectionType) {
    if (data.add) {
      updateSelectedProducts({
        ...selected,
        [data.id]: data.product,
      })
    } else {
      const updated = { ...selected }
      delete updated[data.id]
      updateSelectedProducts(updated)
    }
  }

  function handleSelectAll() {
    const newSelected: Record<number, IProduct> = {}
    data?.data?.forEach((p) => {
      newSelected[Number(p.id)] = p
    })
    updateSelectedProducts(newSelected)
  }

  function handleProductAddComplete() {
    onAddComplete?.()
  }

  const handlePageClick = (event: SelectedPageType) => {
    setPage(event.selected)
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  // function handleShowDetails(slt: number, id: number) {
  //   setShowDetailsViewFor({ slot: slt, id })
  // }

  function handleAdvancedFilterClick() {
    setShowAdvancedFilters(!showAdvancedFilters)
  }

  function handleAdvancedFiltersClose() {
    setShowAdvancedFilters(false)
  }

  const switchToContinuitiesView = () => {
    history.replace({
      ...location,
      state: {
        ...location.state,
        showContinuities: true,
      },
    })
    setIsSlidesVisible(true)
  }

  const switchToProductsView = () => {
    history.replace({
      ...location,
      state: {
        ...location.state,
        showContinuities: false,
      },
    })
    setIsSlidesVisible(false)
  }

  if (showDetailsViewFor !== null) {
    return (
      <div className="flex h-full w-full flex-col">
        <div className="h-16">
          <BackButton onClick={() => setShowDetailsViewFor(null)} text="Back to List" />
        </div>
        <div className="scrollbar overflow-y-scroll px-4 py-5">
          <ProductDetailView
            slot={showDetailsViewFor.slot}
            id={showDetailsViewFor.id}
            onAddSlideComplete={onAddComplete}
            orgMarkup={orgMarkup}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-8xl relative mx-auto">
      <div className="flex items-center">
        <div className="flex w-full items-center justify-between py-6 sm:w-1/2">
          <SearchInput className="w-full" onChange={handleSearch} useCache={useCache} />
        </div>
        <FilterButton onClick={handleAdvancedFilterClick} />
        {!builder || location?.state?.from === 'notes' ? (
          <>
            <Button
              onClick={switchToContinuitiesView}
              size="s"
              variant="outline"
              selected={isSlidesVisible}
              className="ml-auto"
            >
              Continuities
            </Button>
            <Button
              onClick={switchToProductsView}
              size="s"
              variant="outline"
              selected={!isSlidesVisible}
              className="ml-4"
            >
              Products
            </Button>
          </>
        ) : null}
      </div>
      {showAdvancedFilters && <AdvancedFilters onClose={handleAdvancedFiltersClose} />}
      {isSlidesVisible ? (
        <ContinuitiesCardGrid
          userRole={userRole}
          filters={filters}
          filterString={filterString}
          searchTerm={searchTerm}
          sortParams={sort}
        />
      ) : (
        <>
          <ResultsAndSortSection
            data={data}
            userRole={userRole}
            filters={filters}
            onSetCurrentSort={setCurrentProductSort}
            onSetPage={setPage}
            sortOptions={sortOptions}
            sort={sort}
          />
          {/* Sticky Filters Section */}
          <div className="relative flex">
            {/* Filters left side */}
            {userRole === constants.ROLES.USER ? (
              <ClientFilters
                filters={filters}
                setFilters={(data: IAdminFilter[]) => {
                  setFilters(data)
                  setPage(0)
                }}
                onClear={() => {
                  setFilters([])
                  setPage(0)
                }}
                exclusivesOnly={exclusivesOnly}
              />
            ) : null}

            {/* Product content */}
            <div className="w-full px-4 py-5">
              {data?.data && data?.data?.length > 0 && (
                <>
                  <ProductCardGrid
                    data={data.data}
                    ref={scrollRef}
                    selected={selected}
                    onSelect={handleSelect}
                    isAdmin={isAdmin}
                    favorites={favorites}
                    slot={slot}
                    page={page}
                    fromNotes={fromNotes}
                    onAddComplete={handleProductAddComplete}
                    orgMarkup={orgMarkup}
                    // onShowDetails={handleShowDetails}
                    sortParams={sort}
                    currentSlide={currentSlide}
                    onBrandClick={(brand: string) => {
                      const filter = createFilterFromAttribute('brand', brand, 'and')
                      setFilters([filter])
                      setPage(0)
                    }}
                    onVendorClick={(vendor: string) => {
                      const filter = createFilterFromAttribute('vendor', vendor, 'and')
                      setFilters([filter])
                      setPage(0)
                    }}
                  />
                  <div className="border-gr flex h-12 w-full items-center justify-center">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      forcePage={page}
                      pageRangeDisplayed={3}
                      pageCount={totalPages}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      className="flex"
                      breakClassName="hover:text-gray-500 hover:bg-gray-100 px-2"
                      activeClassName="bg-purple-300 text-white hover:bg-purple-300 hover:bg-opacity-80 hover:text-white px-2 text-sm"
                      previousClassName="px-2 font-semibold hover:bg-gray-100"
                      nextClassName="px-2 font-semibold hover:bg-gray-100"
                      pageClassName="hidden md:flex items-center cursor-pointer hover:bg-gray-100 px-2 text-sm"
                    />
                  </div>
                </>
              )}
              {!isLoading && data?.data?.length === 0 && (
                <div className="mt-6 w-full">
                  <p className="text-center font-semibold">
                    Oops... Sorry we did not find anything that matches this search
                  </p>
                  <EmptyState className="mx-auto" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {user && <MultiSelectBar onSelectAll={handleSelectAll} user={user} />}
      {(isFetching || isLoading) && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}
