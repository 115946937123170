import { useMemo } from 'react'
import { classNames } from 'utils/helpers'
import styles from './LoadingSpinner.module.css'

interface ILoadingSpinnerProps {
  size?: 'xs' | 's' | 'm'
}
export const LoadingSpinner = ({ size }: ILoadingSpinnerProps) => {
  const scale = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'scale-25'
      case 's':
        return 'scale-50'
      case 'm':
        return 'scale-75'
      default:
        return ''
    }
  }, [size])
  return (
    <div className={classNames(styles.cssload_container, scale)}>
      <div className={styles.cssload_whirlpool}></div>
    </div>
  )
}
