import { PlusIcon } from '@heroicons/react/solid'
import { useLocation } from 'react-router-dom'

import { NavBar } from 'components/NavBar'
import { BuilderCore } from 'components/continuityBuilder/BuilderCore'
import { BuilderControls } from 'components/continuityBuilder/Controls'
import Thumbnails from 'components/continuityBuilder/Thumbails'

import { addSlide } from 'data/decks'

export default function Builder() {
  const { state } = useLocation<undefined | { from?: 'product_detail' }>()

  return (
    <main className="h-screen">
      <NavBar builder={true} />
      <div style={{ paddingTop: '67px', position: 'relative' }}>
        {/* Static left sidebar for desktop */}
        <aside
          style={{ paddingTop: '67px' }}
          className="z-10 hidden md:fixed md:inset-y-0 md:flex md:w-60 md:flex-col"
        >
          <div className="scrollbar flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <nav id="thumbs" className="flex flex-col mt-5 items-center space-y-1 px-2 pb-4">
              <button
                onClick={addSlide}
                style={{ height: '90px', width: '160px' }}
                className="flex h-full w-full flex-col items-center justify-center rounded border"
              >
                <PlusIcon className="h-12 w-12 text-gray-400" />
              </button>
              <Thumbnails />
            </nav>
          </div>
        </aside>

        {/* Static right sidebar for desktop */}
        <aside
          style={{ paddingTop: '67px' }}
          className="right-0 z-20 hidden shadow-full-light md:fixed md:inset-y-0 md:flex md:w-60 md:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="scrollbar flex flex-grow flex-col overflow-y-auto border-l border-gray-200 bg-white">
            <nav className="flex flex-grow flex-col space-y-1 px-2 py-4">
              <BuilderControls />
            </nav>
          </div>
        </aside>

        <div className="md:px-60">
          <div className="mx-auto flex flex-col">
            {/* Center content */}
            <main
              className="mx-auto flex w-full justify-center px-5"
              style={{ maxWidth: '1100px' }}
            >
              <BuilderCore showAddProductsModalOnLoad={state?.from === 'product_detail'} />
            </main>
          </div>
        </div>
      </div>
    </main>
  )
}
