import { LoadingSpinner } from 'components/LoadingSpinner'
import AddFileModal from 'components/modals/AddFileModal'
import { GenericModal } from 'components/modals/GenericModal'
import { Button } from 'components/small/Button'
import { createDeck, updateDeck } from 'data/decks'
import { IDirectoryCrumb } from 'data/globals/types'
import { useAppSelector } from 'data/reduxHooks'
import { useErrorAlert } from 'hooks/useErrorAlert'
import { useState } from 'react'
import { useAlert } from 'react-alert'
import { IUser } from 'types/modelTypes'

interface ISaveDeckButtonProps {
  user: IUser
}
export const SaveDeckButton = ({ user }: ISaveDeckButtonProps) => {
  const alert = useAlert()
  const { errorAlert } = useErrorAlert()
  const deck = useAppSelector((state) => state.decks)

  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [loading, setLoading] = useState(false)

  async function handleUpdate() {
    setLoading(true)
    try {
      const newMasterDeck = {
        id: deck.id,
        title: deck.title,
        continuities: deck.continuities,
        recipient: deck.recipient,
        type: deck.type,
        filename: deck.filename,
      }
      await updateDeck({
        id: deck.id!,
        newDeck: newMasterDeck,
        filename: newMasterDeck.filename,
        dirId: deck.directoryId!,
        user,
      })
      setShowUpdateModal(false)
      alert.success('Update successful')
    } catch (error) {
      console.log('Error updating continuity', error)
      errorAlert(error, 'Error updating continuity')
    } finally {
      setLoading(false)
    }
  }

  async function handleSave(filename: string, dir: IDirectoryCrumb) {
    try {
      setLoading(true)
      const newMasterDeck = {
        title: deck.title,
        continuities: deck.continuities,
        recipient: deck.recipient,
        type: deck.type,
        filename: filename,
      }
      await createDeck({
        newDeck: newMasterDeck,
        filename,
        dirId: dir.directoryId!,
        user,
      })
      setShowSaveModal(false)
    } catch (error) {
      errorAlert(error, 'Error saving continuity')
    } finally {
      setLoading(false)
    }
  }

  const handleClick = () => {
    if (deck.id) {
      setShowUpdateModal(true)
      return
    }
    setShowSaveModal(true)
  }

  return (
    <>
      <Button variant="primary" size="s" onClick={handleClick} className="mr-4" shadow="none">
        SAVE
      </Button>
      <AddFileModal
        show={showSaveModal}
        title="Save Deck"
        onAccept={handleSave}
        onClose={() => setShowSaveModal(false)}
        loading={loading}
      />
      <GenericModal
        show={showUpdateModal}
        loading={loading}
        title="Update Deck"
        onAccept={handleUpdate}
        acceptText="Save"
        cancelText="Create New"
        onClose={() => setShowUpdateModal(false)}
        onCancel={() => {
          setShowUpdateModal(false)
          setTimeout(() => setShowSaveModal(true), 350)
        }}
      >
        <div className="w-full">
          <p>{deck.filename} already exists. Overwite?</p>
        </div>
        {loading && (
          <div className="absolute top-0 right-0 left-0 bottom-0 z-30 flex items-center justify-center bg-white opacity-70">
            <LoadingSpinner />
          </div>
        )}
      </GenericModal>
    </>
  )
}
