import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory, useParams } from 'react-router-dom'

import { classNames } from 'utils/helpers'

import { TextInput } from 'components/inputs/TextInput'

import { bulkCreateSlides } from 'data/decks'
import { addFavorites } from 'data/favorites'
import { removeProductsFromList, resetPriceAdjustOptions } from 'data/globals'
import { useAppSelector } from 'data/reduxHooks'
import { updateMultiSelect, updateSelectedProducts, updateSelectedSlides } from 'data/selections'
import {
  selectCurrentOption,
  selectSelectedSlides,
  selectedProducts,
} from 'data/selections/selectors'
import { IMultiSelectOption } from 'data/selections/types'
import { selectIsAdmin, selectUser } from 'data/user/selectors'
import { api } from 'services/api'
import { IUser } from 'types/modelTypes'

import { ReactComponent as SendIcon } from '../assets/svgs/send.svg'

interface IMultiSelectBarParams {
  listId: string
}

interface IMultiSelectBarProps {
  onSelectAll?: () => void
  user: IUser
  onDeleteAllFromList?: () => void
}
export function MultiSelectBar({ onSelectAll, user, onDeleteAllFromList }: IMultiSelectBarProps) {
  const alert = useAlert()
  const history = useHistory()
  const params = useParams<IMultiSelectBarParams>()

  const currentOption = useAppSelector(selectCurrentOption)
  const selected = useAppSelector(selectedProducts)
  const selectedSlides = useAppSelector(selectSelectedSlides)
  const isAdmin = useAppSelector(selectIsAdmin)

  const [options, setOptions] = useState<IMultiSelectOption[]>([])
  const [adminOptions, setAdminOptions] = useState<IMultiSelectOption[]>([])

  const fetchLists = async () => {
    try {
      const { data } = await api.List.getForUser({
        userId: user.id,
        search: '',
        order: 'DESC',
        order_by: 'updatedAt',
      })
      //   const { data } = await api.List.getAllForUserSimple(user.id)
      let withLists: IMultiSelectOption[] = []
      if (data.length > 0) {
        data.forEach((list) => {
          if (list.name === 'My Favorites') {
            withLists.push({ id: list.id, name: list.name, value: 'favorite' })
          } else {
            withLists.push({ id: list.id, name: list.name, value: 'list' })
          }
        })
        setOptions(withLists)
      } else {
        setOptions(withLists)
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message)
      }
    }
  }

  useEffect(() => {
    if (isAdmin) {
      setAdminOptions([{ id: 1, name: 'Builder', value: 'bulk' }])
    } else {
      setAdminOptions([])
    }
  }, [isAdmin])

  useEffect(() => {
    // fetch all lists and add them to end of options menu
    if (user?.id) {
      fetchLists()
    }
  }, [user?.id])

  const handleSelectChange = (opt: IMultiSelectOption) => {
    updateMultiSelect(opt)
  }

  const selectAll = () => {
    onSelectAll?.()
  }

  const deselectAll = () => {
    updateSelectedProducts({})
    updateSelectedSlides({})
  }

  const deleteAll = async () => {
    const listId = params?.listId
    if (!listId) {
      alert.error('You need to be looking at the list you want to remove products from.')
      return
    }
    try {
      const productIds = Object.keys(selected).map(Number)
      await removeProductsFromList(Number(listId), productIds)
      updateSelectedProducts({})
      onDeleteAllFromList?.()
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  function handleAddToBuilder() {
    bulkCreateSlides({
      products: Object.values(selected),
      continuities: Object.values(selectedSlides),
    })
    resetPriceAdjustOptions()
    updateSelectedProducts({})
    updateSelectedSlides({})
    history.push('/builder')
  }

  function handleAddToFavorite() {
    try {
      addFavorites(Object.values(selected).map((p) => p.id))
      updateSelectedProducts({})
      if (Object.values(selectedSlides).length > 0) {
        alert.info('Slides cannot be added to Favorites')
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message)
      }
    }
  }

  async function handleAddToList() {
    if (Object.values(selectedSlides).length > 0) {
      alert.info('Slides cannot be added to Lists')
    }
    if (!currentOption) {
      alert.info('Please select a destination')
      return
    }
    try {
      await api.List.addProducts(
        currentOption.id,
        Object.values(selected).map((p) => p.id),
      )
      alert.success(`Added ${Object.keys(selected).length} products to ${currentOption.name}`)
      updateSelectedProducts({})
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message)
      }
    }
  }

  const handleSend = () => {
    const hasSelected = Object.keys(selected).length + Object.keys(selectedSlides).length > 0
    if (currentOption?.value === 'bulk' && hasSelected) {
      handleAddToBuilder()
    } else if (currentOption?.value === 'favorite' && hasSelected) {
      handleAddToFavorite()
    } else if (currentOption?.value === 'list' && hasSelected) {
      handleAddToList()
    } else {
      alert.info('Please select a destination')
    }
  }

  const selectedCount = Object.keys(selected).length + Object.keys(selectedSlides).length
  const hasOnlyProductsFromList = Object.keys(selected).every((id) => {
    return String(selected[Number(id)].listId) === params?.listId
  })

  return selectedCount > 0 ? (
    <div
      style={{ maxWidth: '446px' }}
      className="fixed bottom-14 left-1/2 z-40 flex w-446 -translate-x-1/2 -translate-y-1/2 transform items-center justify-between rounded-full bg-gray-800 p-1 shadow-full-white"
    >
      <div className="w-32 pl-3 text-left text-xxs text-gray-200">
        <span>{`Selected (${selectedCount})`}</span>
      </div>
      {onSelectAll && (
        <button
          onClick={selectAll}
          className="p-1 text-xxs text-gray-200 hover:bg-gray-600 focus:outline-none focus:ring-0"
        >
          Select All
        </button>
      )}
      <button
        onClick={deselectAll}
        className="p-1 text-xxs text-gray-200 hover:bg-gray-600 focus:outline-none focus:ring-0"
      >
        Deselect All
      </button>
      {hasOnlyProductsFromList ? (
        <button
          onClick={deleteAll}
          className="p-1 text-xxs text-gray-200 hover:bg-gray-600 focus:outline-none focus:ring-0"
        >
          Delete
        </button>
      ) : null}
      <MultiSelectMenu
        onChange={handleSelectChange}
        options={options}
        // selectedCount={selectedCount}
        currentOption={currentOption}
        adminOptions={adminOptions}
        onCreateComplete={fetchLists}
      />
      <button
        onClick={handleSend}
        className="pr-3 hover:opacity-60 focus:outline-none focus:ring-0"
      >
        <SendIcon className="h-5 w-5" />
      </button>
    </div>
  ) : null
}

interface IMenuListItemProps {
  quay: string
  option: IMultiSelectOption
  disabled?: boolean
  title?: boolean
}
const MenuListItem = ({ quay, option, disabled = false, title = false }: IMenuListItemProps) => {
  return (
    <ListboxOption
      key={quay}
      disabled={disabled}
      className={({ focus }) =>
        classNames(
          focus && !title ? 'bg-gray-500' : '',
          'relative cursor-default select-none px-6 text-xxs text-gray-200',
        )
      }
      value={option}
    >
      {({ selected, focus }) => (
        <>
          <span
            className={classNames(
              selected ? 'font-semibold' : 'font-normal',
              title ? 'text-[14px] font-bold text-white' : '',
              'block truncate',
            )}
          >
            {option.name}
          </span>

          {selected && !title ? (
            <span
              className={classNames(
                focus ? 'bg-gray-500' : '',
                'absolute inset-y-0 right-1 flex items-center pl-1.5 text-gray-200',
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </>
      )}
    </ListboxOption>
  )
}

interface IMultiSelectMenuProps {
  onChange: (value: IMultiSelectOption) => void
  value?: IMultiSelectOption
  options: IMultiSelectOption[]
  adminOptions: IMultiSelectOption[]
  currentOption: IMultiSelectOption | null
  onCreateComplete: () => void
}
export const MultiSelectMenu = ({
  onChange,
  value,
  options,
  adminOptions,
  currentOption,
  onCreateComplete,
}: IMultiSelectMenuProps) => {
  const [selected, setSelected] = useState(
    currentOption || { id: 0, name: 'Selected Options', value: null },
  )

  useEffect(() => {
    if (value) {
      setSelected(value)
    }
  }, [value])

  function handleChange(v: IMultiSelectOption) {
    setSelected(v)
    onChange(v)
  }

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <div className="relative flex h-8 items-center">
            <ListboxButton
              className={`relative w-full cursor-pointer bg-gray-800 p-1 pr-5 text-center text-xxs text-gray-200 hover:bg-gray-600 focus:outline-none focus:ring-0`}
            >
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <SelectorIcon className="h-4 w-4 text-gray-200" aria-hidden="true" />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions
                static
                className="scrollbar absolute bottom-10 right-0 z-20 max-h-72 w-full min-w-40 overflow-auto rounded-md bg-gray-800 py-2 text-base shadow-full-dark ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <div className="h-2"></div>
                <MenuListItem
                  quay="AddTo"
                  option={{ id: 0, name: 'Add To', value: null }}
                  title={true}
                  disabled={true}
                />
                {adminOptions.length === 0 && (
                  <p className="text-[14px] font-normal text-white text-center">None</p>
                )}
                {adminOptions.map((opt, idx) => (
                  <MenuListItem quay={`admin_opt_${idx}`} key={`admin_opt_${idx}`} option={opt} />
                ))}
                <MenuListItem
                  quay="MyLists"
                  option={{ id: 0, name: 'My Lists', value: null }}
                  title={true}
                  disabled={true}
                />
                {options.length === 0 && (
                  <p className="text-[14px] font-normal text-white text-center">None</p>
                )}
                <AddOptionInput onCreateComplete={onCreateComplete} />
                <div className="scrollbar max-h-24 overflow-y-auto">
                  {options.map((opt, idx) => (
                    <MenuListItem quay={`admin_opt_${idx}`} key={`admin_opt_${idx}`} option={opt} />
                  ))}
                </div>
                <div className="h-2"></div>
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

interface IAddOptionInputProps {
  onCreateComplete: () => void
}
function AddOptionInput({ onCreateComplete }: IAddOptionInputProps) {
  const user = useAppSelector(selectUser)

  const [text, setText] = useState('')

  async function handleAddNewList() {
    if (!text.trim() || !user) return
    try {
      await api.List.create({ userId: user.id + '', name: text })
      setText('')
      setTimeout(() => {
        onCreateComplete()
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ListboxOption disabled={true} value>
      <div className="flex items-center">
        <TextInput
          onChange={setText}
          value={text}
          // prevents space from closing menu
          onKeyDown={(e) => e.stopPropagation()}
          containerClass="mx-2 rounded border border-gray-100"
          inputClass="placeholder-gray-200"
          style={{
            backgroundColor: 'transparent',
            height: '20px',
            fontSize: '10px',
            color: 'white',
          }}
          placeholder="Add new list"
        />
        <PlusCircleIcon
          className="h-8 w-8 cursor-pointer pr-2 text-white"
          onClick={handleAddNewList}
        />
      </div>
    </ListboxOption>
  )
}
