import { useState } from 'react'

import { TextInput } from '../inputs/TextInput'
import { GenericModal } from './GenericModal'

interface IAddFileModalProps {
  title: string
  show: boolean
  onClose: () => void
  onAccept: (fileName: string) => void
}
export default function AddFolderModal({
  title,
  show,
  onClose = () => {},
  onAccept = () => {},
}: IAddFileModalProps) {
  const [folderName, setFolderName] = useState('')

  function handleAccept() {
    onAccept(folderName)
  }

  return (
    <GenericModal title={title} show={show} onClose={onClose} onAccept={handleAccept}>
      <TextInput label="Folder Name" onChange={setFolderName} />
    </GenericModal>
  )
}
